import { Box, FormControlLabel, Switch } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { IContentChannel } from "../../../../../../types/NendaTypes";
import { t } from "i18next";

const ToggleList = ({
  channel,
  setChannel,
}: {
  channel: IContentChannel;
  setChannel: Dispatch<SetStateAction<IContentChannel>>;
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <FormControlLabel
        label={t("customerportal.pages.content_channels.toggle_list.is_public")}
        control={
          <Switch
            size="medium"
            name="isPublic"
            onChange={(e) => {
              setChannel({
                ...channel,
                isPublic: e.target.checked,
              });
            }}
            checked={channel.isPublic}
          />
        }
      />
      <FormControlLabel
        label={t(
          "customerportal.pages.content_channels.toggle_list.auto_shuffle"
        )}
        control={
          <Switch
            size="medium"
            name="autoShuffle"
            onChange={(e) => {
              setChannel({
                ...channel,
                isAutoShuffleEnabled: e.target.checked,
              });
            }}
            checked={channel.isAutoShuffleEnabled}
          />
        }
      />
      <FormControlLabel
        label={t(
          "customerportal.pages.content_channels.toggle_list.sound_enabled"
        )}
        control={
          <Switch
            size="medium"
            name="soundEnabled"
            onChange={(e) => {
              setChannel({
                ...channel,
                hasSound: e.target.checked,
              });
            }}
            checked={channel.hasSound}
          />
        }
      />
      <FormControlLabel
        label={t(
          "customerportal.pages.content_channels.toggle_list.allow_branding_overlay"
        )}
        control={
          <Switch
            size="medium"
            name="brandingAllowed"
            onChange={(e) => {
              setChannel({
                ...channel,
                branding: {
                  ...channel.branding,
                  allowed: e.target.checked,
                },
              });
            }}
            checked={channel.branding?.allowed}
          />
        }
      />
      <FormControlLabel
        label={t("customerportal.pages.content_channels.toggle_list.allow_ads")}
        control={
          <Switch
            size="medium"
            name="adsAllowed"
            onChange={(e) =>
              setChannel({
                ...channel,
                adPermissions: {
                  ...channel.adPermissions,
                  external: {
                    ...channel.adPermissions.external,
                    bannerAllowed: e.target.checked,
                    fullTakeoverAllowed: e.target.checked,
                  },
                },
              })
            }
            checked={
              channel.adPermissions?.external?.bannerAllowed &&
              channel.adPermissions?.external?.fullTakeoverAllowed
            }
          />
        }
      />
      <FormControlLabel
        label={t(
          "customerportal.pages.content_channels.toggle_list.full_takeover"
        )}
        sx={{ ml: "2rem" }}
        control={
          <Switch
            size="medium"
            name="adsFullTakeoverAllowed"
            onChange={(e) => {
              setChannel({
                ...channel,
                adPermissions: {
                  ...channel.adPermissions,
                  external: {
                    ...channel.adPermissions.external,
                    fullTakeoverAllowed: e.target.checked,
                  },
                },
              });
            }}
            checked={channel.adPermissions?.external?.fullTakeoverAllowed}
          />
        }
      />
      <FormControlLabel
        label={t("customerportal.pages.content_channels.toggle_list.banner")}
        sx={{ ml: "2rem" }}
        control={
          <Switch
            size="medium"
            name="adsBannerAllowed"
            onChange={(e) => {
              setChannel({
                ...channel,
                adPermissions: {
                  ...channel.adPermissions,
                  external: {
                    ...channel.adPermissions.external,
                    bannerAllowed: e.target.checked,
                  },
                },
              });
            }}
            checked={channel.adPermissions?.external?.bannerAllowed}
          />
        }
      />
      <FormControlLabel
        label={t(
          "customerportal.pages.content_channels.toggle_list.allow_signage"
        )}
        control={
          <Switch
            size="medium"
            name="signageAllowed"
            onChange={(e) =>
              setChannel({
                ...channel,
                adPermissions: {
                  ...channel.adPermissions,
                  signage: {
                    ...channel.adPermissions.signage,
                    bannerAllowed: e.target.checked,
                    fullTakeoverAllowed: e.target.checked,
                  },
                },
              })
            }
            checked={
              channel.adPermissions?.signage?.bannerAllowed &&
              channel.adPermissions?.signage?.fullTakeoverAllowed
            }
          />
        }
      />
      <FormControlLabel
        label={t(
          "customerportal.pages.content_channels.toggle_list.full_takeover"
        )}
        sx={{ ml: "2rem" }}
        control={
          <Switch
            size="medium"
            name="signageFullTakeoverAllowed"
            onChange={(e) => {
              setChannel({
                ...channel,
                adPermissions: {
                  ...channel.adPermissions,
                  signage: {
                    ...channel.adPermissions.signage,
                    fullTakeoverAllowed: e.target.checked,
                  },
                },
              });
            }}
            checked={channel.adPermissions?.signage?.fullTakeoverAllowed}
          />
        }
      />
      <FormControlLabel
        label={t(
          "customerportal.pages.content_channels.toggle_list.banner"
        )}
        sx={{ ml: "2rem" }}
        control={
          <Switch
            size="medium"
            name="signageBannerAllowed"
            onChange={(e) => {
              setChannel({
                ...channel,
                adPermissions: {
                  ...channel.adPermissions,
                  signage: {
                    ...channel.adPermissions.signage,
                    bannerAllowed: e.target.checked,
                  },
                },
              });
            }}
            checked={channel.adPermissions?.signage?.bannerAllowed}
          />
        }
      />
    </Box>
  );
};

export default ToggleList;
