import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Link,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { t } from "i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  IOrganizationUnit,
  NendaProduct,
  Resource,
  Scope,
} from "../../../../types/NendaTypes";
import { getCompanyIdByPremise } from "../../../utils/company";
import { clipString } from "../../../utils/string";
import PermissionsGate from "../components/PermissionGate";
import SetupCustomerDialog from "../components/Premise/CreatePremise/SetupCustomerDialog";
import { CustomerPortalState } from "../store";
import { getCompanies } from "../store/reducers/companyReducer";
import {
  getPremises,
  getPremisesByCompanyId,
} from "../store/reducers/organizationUnitReducer";
import { selectNavigationScope } from "../store/reducers/workspaceReducer";
import {
  ProductIcon,
  premiseTableData,
} from "../ui-components/table/tableProps/premises";
import { PremiseTableRowData } from "../ui-components/table/types/premise_table";
import { useNavigate } from "react-router-dom";
import DefaultDataGrid from "../ui-components/dataGrid/DefaultDataGrid";

export const convertPremises = (
  basePremises: IOrganizationUnit[]
): PremiseTableRowData[] => {
  if (!basePremises) return [] as PremiseTableRowData[];
  return basePremises.map((premise) => premiseTableData(premise));
};

const Premises = () => {
  const theme = useTheme();
  const scope = useSelector(selectNavigationScope);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const shouldGetAllPremises = !scope.company;

  const companyPremises = useSelector((state: CustomerPortalState) =>
    getPremisesByCompanyId(state, scope.company || "")
  );
  const allPremises = useSelector(getPremises);
  const scopedPremises = shouldGetAllPremises ? allPremises : companyPremises;

  const companies = useSelector(getCompanies);

  const handleToggleCreateModal = () => {
    setShowCreateModal(!showCreateModal);
  };
  
  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
  };

  const checkBusinessHours = (
    businessHours: IOrganizationUnit["businessHours"]
  ) => {
    if (!businessHours) return false;
    return Object.values(businessHours).some((hours) => hours && hours.isOpen);
  };

  const rows: GridRowsProp = [...scopedPremises].map((premise) => {
    const missingInformationForAdService = () => {
      if (!premise.nendaProducts.includes(NendaProduct.SIGNAGE)) return [];

      const fieldsMissing: string[] = [];
      const requiredFields = [
        {
          value: premise.address.city,
          label: t("form.address.city"),
        },
        {
          value: premise.category,
          label: t("form.category"),
        },
        {
          value: premise.dwellTimeInMinutes,
          label: t("customerportal.pages.dashboard.dwell_time"),
        },
        {
          value: premise.numberOfEntries,
          label: t("customerportal.pages.dashboard.entries_per_week"),
        },
        {
          value: checkBusinessHours(premise.businessHours),
          label: t("form.business_hours"),
        },
      ];
      requiredFields.forEach((field) => {
        if (!field.value) {
          fieldsMissing.push(field.label);
        }
      });
      return fieldsMissing;
    };
    const premiseWithCreated = premise as IOrganizationUnit & {
      created?: string;
    };

    return {
      id: premiseWithCreated._id,
      name: premise.name,
      products: premise.nendaProducts.map((product) => product).join(", "),
      category: premise.category,
      company: getCompanyIdByPremise(premise),
      created_at: premiseWithCreated?.createdAt || premiseWithCreated?.created,
      fieldsMissing: missingInformationForAdService().join(", "),
    };
  });

  const navigate = useNavigate();

  const columns: GridColDef[] = [
    { field: "id", flex: 1, headerName: "ID" },
    {
      field: "name",
      flex: 1,
      headerName: t("customerportal.pages.premises.columns.name") ?? "Name",
      renderCell: (params) => {
        return (
          <Link
            sx={{ color: theme.palette.primary.dark, cursor: "pointer" }}
            onClick={() => navigate(`/dashboard/premise/${params.row.id}`)}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "fieldsMissing",
      headerName: "",
      renderCell: (params) => {
        if (!params.value) return null;
        return (
          <PermissionsGate
            restriction={{
              resource: Resource.Premise,
              scopes: [Scope.CanAdministrate],
            }}
          >
            <Tooltip
              title={
                <Box sx={{ padding: "0.5rem" }}>
                  <Box
                    key={params.value}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: "1rem",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {t("customerportal.pages.premises.missing_information")}:
                    </Typography>
                    <Typography>{params.value}</Typography>
                    <Typography>
                      {t("customerportal.pages.premises.no_ads_will_be_shown")}
                    </Typography>
                  </Box>
                </Box>
              }
            >
              <InfoOutlined color="warning" />
            </Tooltip>
          </PermissionsGate>
        );
      },
    },
    {
      field: "products",
      flex: 1,
      headerName:
        t("customerportal.pages.premises.columns.products") ?? "Products",
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            {params.value.split(",").map((product: NendaProduct) => (
              <ProductIcon product={product.trim()} key={product.trim()} />
            ))}
          </Box>
        );
      },
    },
    {
      field: "category",
      flex: 1,
      headerName:
        t("customerportal.pages.premises.columns.category") ?? "Category",
      renderCell: (params) => {
        return <Typography variant="body1">{params.value}</Typography>;
      },
    },
    {
      field: "created_at",
      headerName:
        t("customerportal.pages.premises.columns.created_at") ?? "Created At",
      flex: 1,
      sortable: true,
      valueFormatter: (params) => {
        return new Date(params as string).toLocaleDateString();
      },
    },
    {
      field: "company",
      flex: 1,
      headerName: t("customerportal.pages.premises.columns.company") ?? "Company",
      renderCell: (params) => {
        return (
          <Link
            sx={{ color: theme.palette.primary.dark, cursor: "pointer" }}
            onClick={() => navigate(`/dashboard/company/${params.row.company}`)}
          >
            {clipString(params.value || "", 30)}
          </Link>
        );
      },
      valueGetter: (value) =>
        companies.find((company) => company._id === value)?.name,
    },
  ];

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <PermissionsGate
      restriction={{
        resource: Resource.Premise,
        scopes: [Scope.CanView],
      }}
    >
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            py: "1rem",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              textTransform: "capitalize",
              color: theme.palette.primary.light,
              paddingRight: "1rem",
            }}
          >
            {scopedPremises.length || 0}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              textTransform: "capitalize",
              color: theme.palette.text.primary,
            }}
          >
            {t("customerportal.pages.premises.title")}
          </Typography>
          <Box sx={{ mx: "2rem" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleToggleCreateModal}
              sx={{ marginLeft: "auto" }}
            >
              {t("customerportal.pages.premises.create_modal.title")}
            </Button>
            <SetupCustomerDialog
              onClose={handleCloseCreateModal}
              open={showCreateModal}
            />
          </Box>
        </Box>
        <DefaultDataGrid
          rows={rows}
          columns={columns}
          sortingConfig={[{ field: "created_at", sort: "desc" }]}
          visibleColumns={{
            id: false,
            products: isMobile ? false : true,
            category: isMobile ? false : true,
            company: isMobile ? false : true,
            created_at: isMobile ? false : true,
          }}
        />
      </Box>
    </PermissionsGate>
  );
};

export default Premises;
