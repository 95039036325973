import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@mui/material";
import TextInput from "../../ui-components/input/TextInput";
import { ReactNode } from "react";
import { t } from "i18next";

interface AutoPlayAssetProps {
  enabled: boolean;
  assetId: string;
  onEnabledChange: (enabled: boolean) => void;
  onAssetIdChange: (assetId: string) => void;
}

const AutoPlayAsset: React.FC<AutoPlayAssetProps> = ({
  enabled,
  assetId,
  onEnabledChange,
  onAssetIdChange,
}): ReactNode => {
  const handleEnableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onEnabledChange(event.target.checked);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", my: "1rem" }}>
      <Typography variant="h6">
        {t("customerportal.pages.unit.autoplay_asset.title")}
      </Typography>
      <FormGroup aria-label="auto-play-asset" row sx={{ p: 1 }}>
        <FormControlLabel
          label={t("customerportal.pages.unit.autoplay_asset.enable")}
          control={
            <Switch
              size="small"
              name={assetId}
              onChange={handleEnableChange}
              checked={enabled}
            />
          }
        />
      </FormGroup>
      <TextInput
        id="asset-id"
        value={assetId}
        label={t("customerportal.pages.unit.autoplay_asset.asset_id")}
        onChange={(e) => onAssetIdChange(e.target.value)}
        variant="standard"
        disabled={!enabled}
        sx={{ textTransform: "capitalize", mb: "1.5rem" }}
      />
    </Box>
  );
};

export default AutoPlayAsset;
