import { t } from "i18next";
import { useState } from "react";
import {
  IRoom,
  ISignagePromotion,
  NendaProduct,
  ScheduleContentRefType,
  ScreenOrientation,
  SignageSlotAmount,
} from "../../../../../../types/NendaTypes";
import { store } from "../../../store";
import { updateScreen } from "../../../store/reducers/screenReducer";
import DefaultDialog from "../../../ui-components/dialog/dialog";
import EditScreen from "../screenCard/EditScreen";

export type ScreenInfoType = {
  name: string;
  description?: string;
  defaultContent: string;
  defaultContentRefType: ScheduleContentRefType;
  soundEnabled?: boolean;
  signageSlotsPerHour?: SignageSlotAmount;
  isSignageAsContentEnabled?: boolean;
  arePromotionsEnabled?: boolean;
  promotions: string[];
  numberOfScreens?: number;
  screenOrientation: ScreenOrientation;
};

const initScreenInfo: ScreenInfoType = {
  name: "",
  description: "",
  defaultContent: "",
  defaultContentRefType: ScheduleContentRefType.CONTENT_CHANNEL,
  soundEnabled: false,
  signageSlotsPerHour: 0,
  promotions: [],
  screenOrientation: ScreenOrientation.LANDSCAPE,
};

interface EditScreenDialogProps {
  screen: IRoom;
  onClose: () => void;
  availablePromotions: ISignagePromotion[];
}

const EditScreenDialog: React.FC<EditScreenDialogProps> = ({
  screen,
  onClose,
  availablePromotions,
}) => {
  const [editScreenInfo, setEditScreenInfo] = useState<ScreenInfoType>({
    name: screen?.name || "",
    description: screen?.description,
    defaultContent: screen?.signage.config?.defaultContent,
    defaultContentRefType:
      screen?.signage.config?.defaultContentRefType ||
      ScheduleContentRefType.CONTENT_CHANNEL,
    soundEnabled: screen?.signage.config?.isSoundEnabled,
    signageSlotsPerHour: screen?.signage.config?.numberOfHourlySignageSlots,
    promotions: screen?.signage.schedule.promotions || [],
    numberOfScreens: screen?.signage.config?.numberOfSceens,
    screenOrientation:
      screen?.signage.config?.screenOrientation || ScreenOrientation.LANDSCAPE,
  });

  const handlePromotionChange = (selectedPromotions: string[]) => {
    setEditScreenInfo({ ...editScreenInfo, promotions: selectedPromotions });
  };

  const handleNumberOfScreensChange = (value: number) => {
    setEditScreenInfo({ ...editScreenInfo, numberOfScreens: value });
  };

  const updateScreenInfo = ({ isConfirmed }) => {
    const payload = {
      ...screen,
      name: editScreenInfo.name,
      description: editScreenInfo.description,
      signage: {
        ...screen.signage,
        schedule: {
          ...screen.signage.schedule,
          promotions: editScreenInfo.promotions,
        },
      },
    };

    if (
      screen.nendaProducts.includes(NendaProduct.SIGNAGE) &&
      screen.signage.config
    ) {
      payload.signage.config = {
        ...screen.signage.config,
        isSoundEnabled: editScreenInfo.soundEnabled,
        numberOfHourlySignageSlots: editScreenInfo.signageSlotsPerHour,
        defaultContent: editScreenInfo.defaultContent,
        defaultContentRefType: editScreenInfo.defaultContentRefType,
        numberOfSceens: editScreenInfo.numberOfScreens,
        screenOrientation: editScreenInfo.screenOrientation,
      };
    }

    if (isConfirmed && screen) {
      store.dispatch(updateScreen(payload));
    }
    onClose();
    setEditScreenInfo(initScreenInfo);
  };
  if (!screen) return null;
  return (
    <DefaultDialog
      open={screen._id !== ""}
      onClose={updateScreenInfo}
      title={t("customerportal.pages.dashboard.screen_settings") as string}
    >
      <EditScreen
        screen={screen}
        screenInfoState={editScreenInfo}
        setScreenInfoState={setEditScreenInfo}
        onPromotionChange={handlePromotionChange}
        availablePromotions={availablePromotions}
        onNumberOfScreensChange={handleNumberOfScreensChange}
        numberOfScreens={editScreenInfo.numberOfScreens}
      />
    </DefaultDialog>
  );
};
export default EditScreenDialog;
