import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import ColorPicker from "../../../ColorPicker";
import Clock from "../Clock";
import {
  IFrontLayerClockConfiguration,
  IFrontlayerConfigValues,
  IFrontlayerConfiguration,
} from "../../../../../../../types/NendaTypes";

const ClockConfig = ({
  state,
  handleChange,
}: {
  state: IFrontlayerConfigValues;
  handleChange: (mutatedState: IFrontLayerClockConfiguration) => void;
}) => {
  const handleClockChange = (property: "font" | "color") => (value: string) => {
    handleChange({
      ...state.clock,
      [property]: value,
    });
  };
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {t("customerportal.settings.premise_settings.frontlayer.clock")}
      </AccordionSummary>
      <AccordionDetails
        sx={(theme) => ({
          display: "grid",
          width: "100%",
          gridTemplateColumns: "1fr 1fr",
          [theme.breakpoints.down("md")]: {
            dipslay: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
          },
          gap: "2rem",
        })}
      >
        <Box sx={{ width: 300 }}>
          <Clock
            font={state.clock.font}
            color={state.clock.color}
            onFontChange={handleClockChange("font")}
            onColorChange={handleClockChange("color")}
          />
        </Box>
        <Box>
          <Typography variant="body2">
            {t(
              "customerportal.settings.config_accordions.clock.color"
            )}
          </Typography>
          <ColorPicker
            color={state.clock.color}
            onChange={handleClockChange("color")}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default ClockConfig;
