import {
  Box,
  capitalize,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@mui/material";
import * as React from "react";
import { NendaProduct, Resource, Scope } from "../../../../../types/NendaTypes";
import PermissionsGate from "../PermissionGate";
import { t } from "i18next";

interface NendaProducts {
  selectedProducts: NendaProduct[];
  availableProducts: NendaProduct[];
  onProductSelect: (productName: NendaProduct) => void;
}

const NendaProducts: React.FC<NendaProducts> = ({
  selectedProducts,
  availableProducts,
  onProductSelect,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onProductSelect(event.target.name as NendaProduct);
  };

  return (
    <PermissionsGate
      restriction={{
        resource: Resource.Premise,
        scopes: [Scope.CanAdministrate],
      }}
    >
      <Box>
        <Typography variant="h6">
          {capitalize(t("glossary.product", { count: 2 }))}
        </Typography>
        <FormGroup aria-label="products" row sx={{ p: 1 }}>
          {availableProducts?.includes(NendaProduct.STREAMING_APP) && (
            <FormControlLabel
              label={t("customerportal.pages.premises.streaming")}
              control={
                <Switch
                  size="small"
                  name={NendaProduct.STREAMING_APP}
                  onChange={handleChange}
                  checked={selectedProducts?.includes(
                    NendaProduct.STREAMING_APP
                  )}
                />
              }
            />
          )}
          {availableProducts?.includes(NendaProduct.FRONTLAYER) && (
            <FormControlLabel
              label={t("customerportal.pages.premises.frontlayer")}
              control={
                <Switch
                  size="small"
                  name={NendaProduct.FRONTLAYER}
                  onChange={handleChange}
                  checked={selectedProducts.includes(NendaProduct.FRONTLAYER)}
                />
              }
            />
          )}
          {availableProducts?.includes(NendaProduct.SIGNAGE) && (
            <FormControlLabel
              label={t("customerportal.pages.premises.signage")}
              control={
                <Switch
                  size="small"
                  name={NendaProduct.SIGNAGE}
                  onChange={handleChange}
                  checked={selectedProducts.includes(NendaProduct.SIGNAGE)}
                />
              }
            />
          )}
          {availableProducts?.includes(NendaProduct.SIGNAL_DELIVERY) && (
            <FormControlLabel
              label={t("customerportal.pages.premises.signal_delivery")}
              control={
                <Switch
                  size="small"
                  name={NendaProduct.SIGNAL_DELIVERY}
                  onChange={handleChange}
                  checked={selectedProducts.includes(
                    NendaProduct.SIGNAL_DELIVERY
                  )}
                />
              }
            />
          )}
        </FormGroup>
      </Box>
    </PermissionsGate>
  );
};

export default NendaProducts;
