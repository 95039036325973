import { PersonAddOutlined } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Resource,
  Role,
  Scope,
  SimpleUser,
} from "../../../../types/NendaTypes";
import PermissionsGate from "../components/PermissionGate";
import {
  CreateEditUserForm,
  isPremiseSelectVisible,
} from "../components/User/CreateEditUserForm";
import UserList from "../components/User/UserList";
import { store } from "../store";
import {
  clearLoadingStatus,
  createUser,
  selectCreateUserLoading,
  selectCreateUserSucceeded,
  selectUpdateUserLoading,
  selectUpdateUserSucceeded,
  selectUsers,
  updateUser,
} from "../store/reducers/userReducer";
import DefaultDialog from "../ui-components/dialog/dialog";

export type CreateUserProps = {
  _id: string;
  email: string;
  password?: string;
  role?: Role | undefined;
  company?: string | undefined;
  shouldSendEmail: boolean;
  selectedPremises: string[];
};

const Users = () => {
  const users = useSelector(selectUsers);
  const [userToEdit, setUserToEdit] = useState<SimpleUser | undefined>();

  const handleSetEditUser = (userId: string) => {
    setUserToEdit(users.data.find((u) => u._id === userId) || undefined);
  };

  const clearUserToEdit = () => {
    setUserToEdit(undefined);
  };

  return (
    <PermissionsGate
      restriction={{
        resource: Resource.User,
        scopes: [Scope.CanCreate],
      }}
    >
      <>
        <Stack direction={"row"} alignItems={"center"} mb={"2rem"} gap="1rem">
          <Typography
            variant="h3"
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            {t("customerportal.pages.users.title")}
          </Typography>
          <CreateEditUserButton
            userToEdit={userToEdit}
            clearUserToEdit={clearUserToEdit}
          />
        </Stack>
        <Stack gap={2}>
          <UserList onEditUser={handleSetEditUser} />
        </Stack>
      </>
    </PermissionsGate>
  );
};

type CreateEditUserButtonProps = {
  userToEdit?: SimpleUser;
  clearUserToEdit: () => void;
};

const CreateEditUserButton = ({
  userToEdit,
  clearUserToEdit,
}: CreateEditUserButtonProps) => {
  const [showUserForm, setShowUserForm] = useState(false);
  const editSuccess = useSelector(selectUpdateUserSucceeded);
  const createSuccess = useSelector(selectCreateUserSucceeded);
  const editLoading = useSelector(selectUpdateUserLoading);
  const createLoading = useSelector(selectCreateUserLoading);
  const isLoading = userToEdit ? editLoading : createLoading;
  const loadingSucceeded = userToEdit ? editSuccess : createSuccess;
  const [isSubmittable, setIsSubmittable] = useState(false);

  const handleShowUserForm = () => {
    setShowUserForm(true);
  };

  const handleClearUserForm = () => {
    formik.resetForm();
    clearUserToEdit();
  };

  const handleCloseUserForm = () => {
    setShowUserForm(false);
    handleClearUserForm();
  };

  const handleCreateUserClick = (values: CreateUserProps) => {
    const {
      email,
      password,
      role,
      company,
      shouldSendEmail,
      selectedPremises,
    } = values;

    const updateUserDetails: Partial<CreateUserProps> = {
      _id: userToEdit?._id,
      email,
      role,
      company,
      shouldSendEmail,
      password,
      selectedPremises: isPremiseSelectVisible(role) ? selectedPremises : [],
    };
    if (!updateUserDetails.password) {
      delete updateUserDetails.password;
    }

    if (email && role && company) {
      if (userToEdit?._id) {
        store.dispatch(updateUser({ ...updateUserDetails }));
      } else {
        store.dispatch(
          createUser({
            email,
            password: password || "",
            type: role,
            company,
            shouldSendEmail,
            premises: isPremiseSelectVisible(role) ? selectedPremises : [],
          })
        );
      }
    }
  };

  const initialValues: CreateUserProps = {
    _id: userToEdit?._id || "",
    password: userToEdit?.password || "",
    role: userToEdit?.type || undefined,
    email: userToEdit?.email || "",
    shouldSendEmail: false,
    company: userToEdit?.company || "",
    selectedPremises: userToEdit?.premises || [],
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const handleSubmit = ({ isConfirmed }: { isConfirmed: boolean }) => {
    if (!isConfirmed) {
      handleCloseUserForm();
    } else {
      handleCreateUserClick(formik.values);
    }
  };

  useEffect(() => {
    if (userToEdit) {
      setShowUserForm(true);
    }
  }, [userToEdit]);

  useEffect(() => {
    if (loadingSucceeded) {
      handleCloseUserForm();
      store.dispatch(clearLoadingStatus());
    }
  }, [loadingSucceeded, handleCloseUserForm]);

  return (
    <>
      <Button variant="contained" size="small" onClick={handleShowUserForm}>
        {t("customerportal.pages.users.create_user")}{" "}
        <PersonAddOutlined sx={{ height: "1rem", ml: "0.5rem" }} />
      </Button>
      <DefaultDialog
        sx={{ position: "relative" }}
        disabled={!isSubmittable}
        open={showUserForm}
        onClose={handleSubmit}
        title={
          formik.values._id
            ? t("customerportal.pages.users.edit_user") ?? "Edit User"
            : t("customerportal.pages.users.create_new_user") ?? "Create User"
        }
        spinner={isLoading}
      >
        <CreateEditUserForm
          setFieldValue={formik.setFieldValue}
          email={formik.values.email}
          password={formik.values.password || ""}
          role={formik.values.role}
          company={formik.values.company || ""}
          shouldSendEmail={formik.values.shouldSendEmail}
          selectedPremiseIds={formik.values.selectedPremises}
          isEdit={!!formik.values._id}
          isSubmittable={setIsSubmittable}
        />
      </DefaultDialog>
    </>
  );
};

export default Users;
