import { SxProps, Typography, useTheme } from "@mui/material";

const PageTitle = ({ title, sx }: { title: string; sx?: SxProps }) => {
  const theme = useTheme();
  return (
    <Typography
      variant="h3"
      sx={{
        color: theme.palette.text.primary,
        my: "2rem",
        ...sx,
      }}
    >
      {title}
    </Typography>
  );
};

export default PageTitle;
