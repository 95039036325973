import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Box, Button, Divider, Grid, Paper } from "@mui/material";
import { t } from "i18next";
import React, { SyntheticEvent } from "react";
import { useSelector } from "react-redux";
import {
  IRoom,
  IRoomType,
  NendaProduct,
  Resource,
  RoomStatus,
  Scope,
} from "../../../../../types/NendaTypes";
import ConfirmationDialog from "../../ConfirmationDialog";
import { CustomerPortalState, store } from "../../store";
import { selectRoomTypesByPremiseGroupId } from "../../store/reducers/organizationUnitReducer";
import { deleteScreen, updateScreen } from "../../store/reducers/screenReducer";
import { selectStreamChannels } from "../../store/reducers/streamChannelReducer";
import TextInput from "../../ui-components/input/TextInput";
import PageTitle from "../../ui-components/typography/PageTitle";
import DynamicToggleList, { ToggleItem } from "../DynamicToggleList";
import PermissionsGate from "../PermissionGate";
import NendaProducts from "../Premise/NendaProducts";
import StreamChannelSelect from "../Premise/Screen/SignalDelivery/StreamChannelSelect";
import AutoPlayAsset from "./AutoplayAsset";
import RoomTypeSelect from "./RoomType/RoomTypeSelect";
import StatusSelect from "./StatusSelect";
import { useNavigate } from "react-router-dom";
import { selectNavigatedPremiseId } from "../../store/reducers/workspaceReducer";

interface UnitProps {
  unit: IRoom;
  productsOnPremise: NendaProduct[];
}

const Unit: React.FC<UnitProps> = ({ unit, productsOnPremise }) => {
  const [state, setState] = React.useState<IRoom>({ ...unit });
  const navigatedPremise = useSelector(selectNavigatedPremiseId);

  const roomTypes = useSelector((state: CustomerPortalState): IRoomType[] =>
    selectRoomTypesByPremiseGroupId(state, unit.hotelChain)
  );

  const availableStreamChannels = useSelector(selectStreamChannels);

  const handleRoomTypeChange = (roomTypeId: string) => {
    const updatedUnit = {
      ...unit,
      roomType: roomTypeId,
    };

    setState(updatedUnit);
  };

  const handleStatusChange = (status: RoomStatus) => {
    setState({ ...state, status });
  };

  const handleProductChange = (productName: NendaProduct) => {
    let updatedProducts = [...state.nendaProducts];
    if (updatedProducts.indexOf(productName) === -1) {
      updatedProducts.push(productName);
    } else {
      updatedProducts = updatedProducts.filter((x) => x != productName);
    }
    setState({ ...state, nendaProducts: updatedProducts });
  };

  const handleStreamChannelChange = (selectedChannelIds: string[]) => {
    const updatedState: IRoom = JSON.parse(JSON.stringify(state));

    updatedState.streamChannels = selectedChannelIds;
    setState(updatedState);
  };

  const saveChanges = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    store.dispatch(updateScreen(state));
  };

  const curriedTextInputChange =
    (key: string): ((e: React.ChangeEvent<HTMLInputElement>) => void) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, [key]: e.target.value });
    };

  const handleDeviceIdChange = curriedTextInputChange("deviceId");
  const handleNameChange = curriedTextInputChange("name");
  const handleDescriptionChange = curriedTextInputChange("description");

  const handleAutoplayAssetEnabledChange = (enabled: boolean) => {
    setState({
      ...state,
      autoplayAsset: {
        ...(state.autoplayAsset || { id: "" }),
        enabled,
      },
    });
  };

  const handleAutoplayAssetIdChange = (assetId: string) => {
    setState({
      ...state,
      autoplayAsset: {
        ...(state.autoplayAsset || { enabled: false }),
        id: assetId,
      },
    });
  };

  const roomHasStreamingApp = state.nendaProducts?.includes(
    NendaProduct.STREAMING_APP
  );

  const handleArePromotionsDisabledChange = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    if (!state.signage.config) return;

    setState({
      ...state,
      signage: {
        ...state.signage,
        config: {
          ...state.signage.config,
          arePromotionsEnabled: checked,
        },
      },
    });
  };
  const handleSignageAsContentChange = (
    _event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    if (!state.signage.config) return;

    setState({
      ...state,
      signage: {
        ...state.signage,
        config: {
          ...state.signage.config,
          isSignageAsContentEnabled: checked,
        },
      },
    });
  };

  const signageFeatureToggles: ToggleItem[] = [
    {
      label: t("customerportal.pages.unit.permissions.are_promotions_enabled"),
      value: state.signage.config?.arePromotionsEnabled || false,
      onChange: handleArePromotionsDisabledChange,
      disabled: false,
    },
    {
      label: t(
        "customerportal.pages.unit.permissions.is_signage_as_content_enabled"
      ),
      value: state.signage.config?.isSignageAsContentEnabled || false,
      onChange: handleSignageAsContentChange,
      disabled: false,
    },
  ];

  const handleAllExternalAdsChange = (
    _event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    if (!state.signage.config?.adPermissions.external) return;

    setState({
      ...state,
      signage: {
        ...state.signage,
        config: {
          ...state.signage.config,
          adPermissions: {
            ...state.signage.config.adPermissions,
            external: {
              ...state.signage.config.adPermissions.external,
              bannerAllowed: checked,
              fullTakeoverAllowed: checked,
            },
          },
        },
      },
    });
  };

  const handleAllPromotionsChange = (
    _event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    if (!state.signage.config?.adPermissions.signage) return;

    setState({
      ...state,
      signage: {
        ...state.signage,
        config: {
          ...state.signage.config,
          adPermissions: {
            ...state.signage.config.adPermissions,
            signage: {
              ...state.signage.config.adPermissions.signage,
              bannerAllowed: checked,
              fullTakeoverAllowed: checked,
            },
          },
        },
      },
    });
  };

  const handlePermissionChange = (
    scope: "signage" | "external",
    adType: "bannerAllowed" | "fullTakeoverAllowed",
    checked: boolean
  ) => {
    if (!state.signage.config?.adPermissions.signage) return;

    setState({
      ...state,
      signage: {
        ...state.signage,
        config: {
          ...state.signage.config,
          adPermissions: {
            ...state.signage.config.adPermissions,
            [scope]: {
              ...state.signage.config.adPermissions[scope],
              [adType]: checked,
            },
          },
        },
      },
    });
  };

  const adPermissionToggles: ToggleItem[] = [
    {
      label: t("customerportal.pages.unit.permissions.all_ads"),
      value:
        (state.signage.config?.adPermissions.external.bannerAllowed &&
          state.signage.config?.adPermissions.external.fullTakeoverAllowed) ||
        false,
      onChange: handleAllExternalAdsChange,
      disabled: false,
      children: [
        {
          label: t("customerportal.pages.unit.permissions.full_takeover"),
          value:
            state.signage.config?.adPermissions.external.fullTakeoverAllowed ||
            false,
          onChange: (_event: React.SyntheticEvent, checked: boolean) =>
            handlePermissionChange("external", "fullTakeoverAllowed", checked),
          disabled: false,
        },
        {
          label: t("customerportal.pages.unit.permissions.banner"),
          value:
            state.signage.config?.adPermissions.external.bannerAllowed || false,
          onChange: (_event: React.SyntheticEvent, checked: boolean) =>
            handlePermissionChange("external", "bannerAllowed", checked),
          disabled: false,
        },
      ],
    },
  ];

  const promotionToggles: ToggleItem[] = [
    {
      label: t("customerportal.pages.unit.permissions.all_promotions"),
      value:
        (state.signage.config?.adPermissions.signage.bannerAllowed &&
          state.signage.config?.adPermissions.signage.fullTakeoverAllowed) ||
        false,
      onChange: handleAllPromotionsChange,
      disabled: false,
      children: [
        {
          label: t("customerportal.pages.unit.permissions.full_takeover"),
          value:
            state.signage.config?.adPermissions.signage.fullTakeoverAllowed ||
            false,
          onChange: (_event: React.SyntheticEvent, checked: boolean) =>
            handlePermissionChange("signage", "fullTakeoverAllowed", checked),
          disabled: false,
        },
        {
          label: t("customerportal.pages.unit.permissions.banner"),
          value:
            state.signage.config?.adPermissions.signage.bannerAllowed || false,
          onChange: (_event: React.SyntheticEvent, checked: boolean) =>
            handlePermissionChange("signage", "bannerAllowed", checked),
          disabled: false,
        },
      ],
    },
  ];

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    React.useState(false);

  const handleDeleteButtonClick = () => {
    setShowDeleteConfirmation(true);
  };

  const navigate = useNavigate();

  const handleDeleteConfirmationClose = (isConfirmed: boolean) => {
    setShowDeleteConfirmation(false);
    if (isConfirmed) {
      store.dispatch(deleteScreen(state._id));
      navigate(`/premise/${navigatedPremise}/units`);
    }
  };

  return (
    <Box>
      <ConfirmationDialog
        isOpen={showDeleteConfirmation}
        title={t("customerportal.pages.unit.delete_confirmation.title")}
        content={t("customerportal.pages.unit.delete_confirmation.description")}
        onClose={handleDeleteConfirmationClose}
      />

      <Box
        sx={{
          display: "flex",
          gap: "1.5rem",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <PageTitle
          title={t("customerportal.pages.unit.edit_screen") + " - " + unit.name}
        />
        <Box sx={{ display: "flex", gap: "1.5rem", alignItems: "center" }}>
          <Button variant="danger" onClick={handleDeleteButtonClick}>
            {t("common.delete")}
          </Button>
          <Button
            type="button"
            onClick={saveChanges}
            variant="contained"
            endIcon={<SaveAltIcon />}
          >
            {t("common.save")}
          </Button>
        </Box>
      </Box>
      <Paper
        sx={{
          flexGrow: 1,
          p: "1.5rem",
          mt: "1rem",
          borderRadius: "0.2rem",
          mb: "5rem",
        }}
      >
        <Grid container columns={2} spacing={6}>
          <Grid item xs={2} md={1}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <NendaProducts
                selectedProducts={state.nendaProducts}
                availableProducts={productsOnPremise}
                onProductSelect={handleProductChange}
              />
              <Divider />
              <TextInput
                id="name"
                value={state.name}
                label={t("customerportal.pages.unit.name")}
                onChange={handleNameChange}
                variant="standard"
                sx={{ textTransform: "capitalize", mb: "1.5rem" }}
              />
              <TextInput
                id="description"
                value={state.description}
                label={t("customerportal.pages.unit.description")}
                onChange={handleDescriptionChange}
                variant="standard"
                sx={{ textTransform: "capitalize", mb: "1.5rem" }}
              />
              <TextInput
                id="device-id"
                value={state.deviceId}
                label={t("customerportal.pages.unit.device_id")}
                onChange={handleDeviceIdChange}
                variant="standard"
                sx={{ textTransform: "capitalize", mb: "1.5rem" }}
              />
            </Box>
          </Grid>
          <Grid item xs={2} md={1}>
            {state.nendaProducts.includes(NendaProduct.SIGNAGE) && (
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              >
                <DynamicToggleList
                  title={
                    t(
                      "customerportal.pages.unit.permissions.signage_features"
                    ) as string
                  }
                  toggles={signageFeatureToggles}
                />
                <Divider />
                <DynamicToggleList
                  title={
                    t(
                      "customerportal.pages.unit.permissions.allow_ads"
                    ) as string
                  }
                  toggles={adPermissionToggles}
                />
                <Divider />
                <DynamicToggleList
                  title={
                    t(
                      "customerportal.pages.unit.permissions.allow_promotions"
                    ) as string
                  }
                  toggles={promotionToggles}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={2} md={1}>
            {roomHasStreamingApp && (
              <RoomTypeSelect
                roomTypes={roomTypes}
                selectedRoomTypeId={state.roomType}
                onChange={handleRoomTypeChange}
              />
            )}
            <Divider />

            <StatusSelect status={state.status} onChange={handleStatusChange} />
            <Divider />
            <AutoPlayAsset
              enabled={
                state.autoplayAsset ? state.autoplayAsset?.enabled : false
              }
              assetId={state.autoplayAsset ? state.autoplayAsset?.id : ""}
              onEnabledChange={handleAutoplayAssetEnabledChange}
              onAssetIdChange={handleAutoplayAssetIdChange}
            />
          </Grid>
          <Grid item xs={2} md={1}>
            {state.nendaProducts?.includes(NendaProduct.SIGNAL_DELIVERY) && (
              <PermissionsGate
                restriction={{
                  resource: Resource.StreamChannel,
                  scopes: [Scope.CanAdministrate],
                }}
              >
                <StreamChannelSelect
                  availableChannels={availableStreamChannels}
                  selectedChannelIds={state.streamChannels}
                  onChange={handleStreamChannelChange}
                />
              </PermissionsGate>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default Unit;
