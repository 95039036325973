import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import { t } from "i18next";

interface FileUploadProps {
  onFileSelect: (event: any) => void;
  onUpload: () => void;
  file?: File;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  onUpload,
  onFileSelect,
  file,
}) => {
  const selectFile = (event: any) => {
    onFileSelect(event);
  };

  const upload = () => {
    onUpload();
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        alignItems: "center",
        justifyContent: "space-between",
        maxWidth: "100%",
      }}
    >
      <Button variant="contained" component="label">
        {t("customerportal.settings.config_accordions.globals.select_image")}
        <input type="file" accept="image/*" onChange={selectFile} hidden />
      </Button>
      {file && (
        <Fragment>
          <Stack>
            <img
              style={{ maxWidth: "200px" }}
              src={URL.createObjectURL(file)}
            />
            <Typography>{file.name}</Typography>
          </Stack>
          <Button variant="contained" onClick={upload}>
            {t(
              "customerportal.settings.config_accordions.globals.upload"
            )}
          </Button>
        </Fragment>
      )}
    </Box>
  );
};

export default FileUpload;
