import { Button, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "i18next";
import { useState } from "react";
import {
  FrontLayerInformationItem,
  FrontLayerInformationSource,
  IFrontLayerInformationImageContent,
} from "../../../../../../types/NendaTypes";
import EditInformationItem from "./EditInformationItem";
import PreviewInformationItem from "./PreviewInformationItem";
import { ActionButtonProps } from "../../../ui-components/overlayComponents/ButtonOverlayList";

type EditPreviewInformationItemProps = {
  informationItem: FrontLayerInformationItem;
  onTypeChange: (type: FrontLayerInformationSource) => void;
  configActions: ActionButtonProps[];
  onUrlChange: (url: string) => void;
  onImagesChange: (
    images: IFrontLayerInformationImageContent["images"]
  ) => void;
};
const EditPreviewInformationItem = ({
  informationItem,
  onTypeChange,
  onUrlChange,
  onImagesChange,
  configActions,
}: EditPreviewInformationItemProps) => {
  const theme = useTheme();
  const [tab, setTab] = useState("edit");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const tabKeys = ["edit", "preview"];

  const tabLabels: Record<string, string> = {
    edit: t("customerportal.pages.frontlayer.edit"),
    preview: t("customerportal.pages.frontlayer.pre_view"),
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        p: "1rem",
        borderLeft: "1px solid #eee",
        gap: "1rem",
      }}
    >
      {!informationItem ? (
        <Box>
          <Typography>
            {t("customerportal.pages.frontlayer.no_items")}
          </Typography>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tabs
              value={tab}
              onChange={handleTabChange}
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              {tabKeys.map((tabKey) => (
                <Tab key={tabKey} label={tabLabels[tabKey]} value={tabKey} />
              ))}
            </Tabs>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              {configActions.map((action) => {
                return (
                  <Box key={action.label}>
                    <Button
                      onClick={action.onClick}
                      disabled={action.disabled}
                      variant={action.variant}
                    >
                      {action.label}
                    </Button>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box sx={{ px: "1rem" }}>
            {informationItem && (
              <Typography variant="h6" sx={{ py: "1rem" }}>
                {informationItem.name}
              </Typography>
            )}
            {tab === "edit" && (
              <EditInformationItem
                onTypeChange={onTypeChange}
                onUrlChange={onUrlChange}
                onImagesChange={onImagesChange}
                informationItem={informationItem}
              />
            )}
            {tab === "preview" && informationItem && (
              <PreviewInformationItem informationItem={informationItem} />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default EditPreviewInformationItem;
