import { PlayCircleFilled } from "@mui/icons-material";
import {
  Box,
  Card,
  CardMedia,
  Typography,
  capitalize,
  useTheme,
} from "@mui/material";
import { AssetType, IAsset } from "../../../../../types/NendaTypes";
import { clipString } from "../../../../utils/string";
import { t } from "i18next";

const AssetCard = ({
  asset,
  onClick,
}: {
  asset: IAsset;
  onClick: (value: IAsset) => void;
}) => {
  const theme = useTheme();
  const thumbnail = asset?.thumbnailLocator || asset?.locator;
  const handleOnClick = () => {
    onClick(asset);
  };

  const assetType =
    asset.type === AssetType.VIDEO
      ? t("customerportal.pages.assets.cards.video")
      : asset.type === AssetType.IMAGE
      ? t("customerportal.pages.assets.cards.image")
      : asset.type === AssetType.LINEAR_CHANNEL
      ? t("customerportal.pages.assets.cards.linear")
      : asset.type === AssetType.URL
      ? "Url"
      : asset.type === AssetType.HTML
      ? "Html"
      : "";

  return (
    <Card
      onClick={handleOnClick}
      elevation={0}
      sx={(theme) => ({
        height: "13rem",
        display: "flex",
        boxShadow: "18px 30px 82px -2px rgba(0,0,0,0.12)",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: "0.3rem",
        position: "relative",
        cursor: "pointer",
        transition: "outline 0.2s ease",
        outline: "3px solid transparent",
        "&:hover": { outline: `3px solid ${theme.palette.primary.light}` },
        backgroundColor: "#fff",
        backgroundImage:
          "linear-gradient(45deg, #dcdcdc 25%, transparent 25%), linear-gradient(-45deg, #dcdcdc 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #dcdcdc 75%), linear-gradient(-45deg, transparent 75%, #dcdcdc 75%)",
        backgroundSize: "20px 20px",
        backgroundPosition: "0 0, 0 10px, 10px -10px, -10px 0px",
      })}
    >
      <CardMedia
        image={thumbnail}
        sx={{
          height: "70%",
          background: theme.palette.transparent.blue,
          borderRadius: "0.1rem",
          // backgroundImage: thumbnail && `url(${thumbnail})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: `1px solid ${theme.palette.primary.light}` + 10,
          overflow: "hidden",
        }}
      >
        {asset.type === "video" && (
          <Box
            sx={(theme) => ({
              background: theme.palette.gradient.darkBlue,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            })}
          >
            <PlayCircleFilled
              sx={{ fontSize: "5rem", color: theme.palette.common.white }}
            />
          </Box>
        )}
      </CardMedia>
      <Box
        sx={{
          padding: "0.5rem",
          display: "flex",
          height: "30%",
          flexDirection: "column",
          justifyContent: "center",
          backgroundColor: "#fff",
        }}
      >
        <Typography variant="body2">{clipString(asset.name, 30)}</Typography>
        <Typography variant="subtitle2" sx={{ color: theme.palette.grey[700] }}>
          {capitalize(clipString(assetType, 30))}
        </Typography>
      </Box>
    </Card>
  );
};

export default AssetCard;
