import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "@shared/locales/en/translation.json";
import translationSV from "@shared/locales/sv/translation.json";
const resources = {
  en: {
    translation: translationEN,
  },
  sv: {
    translation: translationSV,
  },
};

const storedLanguage = localStorage.getItem("selectedLanguage");

const browserLanguage = navigator.language.split("-")[0];

const supportedLanguages = ["en", "sv"];

const defaultLanguage =
  storedLanguage ||
  (supportedLanguages.includes(browserLanguage) ? browserLanguage : "en");

i18n
  .use(initReactI18next) 
  .init({
    resources,
    lng: defaultLanguage, 
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
