import {
  BrandingWatermarkOutlined,
  DateRangeOutlined,
  LiveTvOutlined,
  MmsOutlined,
  PermMediaOutlined,
  PlaylistPlayOutlined,
  SubscriptionsOutlined,
  TvOutlined,
} from "@mui/icons-material";
import AddBusinessOutlinedIcon from "@mui/icons-material/AddBusinessOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";
import { capitalize } from "@mui/material";
import {
  Company,
  IOrganizationUnit,
  NavigationLevel,
  NendaProduct,
  Resource,
  Restriction,
  Role,
  Scope,
} from "../../../../types/NendaTypes";
import i18next from "../i18n";

export type MenuItem = {
  id: string;
  title: string;
  caption?: string;
  type: string;
  children?: MenuItem[];
  url?: string;
  icon?: any;
  chip?: any;
  breadcrumbs?: boolean;
  target?: boolean;
  external?: boolean;
  disabled?: boolean;
  restriction?: Restriction;
  products?: NendaProduct[];
  level?: NavigationLevel[];
};

export const dynamicMenu = ({
  scope,
  role,
  navigationLevel,
}: {
  scope: {
    premise: IOrganizationUnit | undefined;
    scopedPremises?: IOrganizationUnit[];
    company: Company | undefined;
  };
  role: Role | undefined;
  navigationLevel?: NavigationLevel;
}) => {
  const isGlobalAdmin = role
    ? [Role.Admin, Role.Superuser].includes(role)
    : false;
  const companyId = scope.company?._id;
  const premiseId = scope.premise?._id;
  const shouldShowPromotionsOnPremiseLevel =
    scope.premise?.signageConfig?.arePromotionsEnabled &&
    scope.premise?.nendaProducts?.includes(NendaProduct.SIGNAGE);
  const shouldShowPromotionsOnCompanyLevel = scope.scopedPremises?.some(
    (p) =>
      p.signageConfig?.arePromotionsEnabled &&
      p.nendaProducts?.includes(NendaProduct.SIGNAGE)
  );
  const shouldShowDigitalSignageOnPremiseLevel =
    scope.premise?.signageConfig?.isSignageAsContentEnabled &&
    scope.premise?.nendaProducts?.includes(NendaProduct.SIGNAGE);
  const shouldShowDigitalSignageOnCompanyLevel = scope.scopedPremises?.some(
    (p) =>
      p.signageConfig?.isSignageAsContentEnabled &&
      p.nendaProducts?.includes(NendaProduct.SIGNAGE)
  );
  const showPromotions =
    navigationLevel === NavigationLevel.PREMISE
      ? shouldShowPromotionsOnPremiseLevel
      : shouldShowPromotionsOnCompanyLevel;

  const showDigitalSignage =
    navigationLevel === NavigationLevel.PREMISE
      ? shouldShowDigitalSignageOnPremiseLevel
      : shouldShowDigitalSignageOnCompanyLevel;
  const adminMenu: MenuItem = {
    id: "admin",
    title: "Menu",
    type: "group",
    children: [
      {
        id: "premiselist",
        title: i18next.t("customerportal.sidemenu.premises"),
        type: "item",
        url: `admin/premiselist`,
        icon: AddBusinessOutlinedIcon,
        restriction: {
          resource: Resource.Company,
          scopes: [Scope.CanAdministrate],
        },
        breadcrumbs: false,
      },
      {
        id: "companies",
        title: i18next.t("customerportal.sidemenu.companies"),
        type: "item",
        url: "admin/companies",
        icon: BusinessOutlinedIcon,
        breadcrumbs: false,
        restriction: {
          resource: Resource.Company,
          scopes: [Scope.CanAdministrate],
        },
      },
      {
        id: "content",
        title: i18next.t("customerportal.sidemenu.effect_content"),
        type: "item",
        url: "admin/content",
        icon: OndemandVideoOutlinedIcon,
        breadcrumbs: false,
        restriction: {
          resource: Resource.Ads,
          scopes: [Scope.CanAdministrate],
        },
      },
      {
        id: "signal-delivery",
        title: i18next.t("customerportal.sidemenu.signal_delivery"),
        type: "item",
        url: "admin/signaldelivery/channel",
        icon: OndemandVideoOutlinedIcon,
        breadcrumbs: false,
        restriction: {
          resource: Resource.StreamChannel,
          scopes: [Scope.CanAdministrate],
        },
      },
      {
        id: "users",
        title: i18next.t("customerportal.sidemenu.users"),
        type: "item",
        url: `/users`,
        icon: GroupOutlinedIcon,
        breadcrumbs: false,
        restriction: {
          resource: Resource.User,
          scopes: [Scope.CanAdministrate],
        },
      },
    ],
  };

  const mainMenu: MenuItem = {
    id: "main",
    title: "Menu",
    type: "group",
    children: [
      {
        id: "dashboard",
        title: i18next.t("customerportal.sidemenu.dashboard"),
        type: "item",
        url: premiseId
          ? `/dashboard/premise/${premiseId}`
          : companyId
          ? `/dashboard/company/${companyId}`
          : "/",
        icon: DashboardOutlinedIcon,
        level: [NavigationLevel.PREMISE, NavigationLevel.COMPANY],
        breadcrumbs: false,
      },
      {
        id: "scheduling",
        title: i18next.t("customerportal.sidemenu.scheduling"),
        type: "collapse",
        url: `/scheduling/schedule/premise/${premiseId}`,
        icon: DateRangeOutlined,
        products: [NendaProduct.SIGNAGE],
        level: [NavigationLevel.PREMISE],
        restriction: {
          resource: Resource.Premise,
          scopes: [Scope.CanView],
        },
        children: [
          {
            id: "schedule",
            title: i18next.t("customerportal.sidemenu.schedule"),
            type: "item",
            url: `/scheduling/schedule/premise/${premiseId}`,
            icon: DateRangeOutlined,
            restriction: {
              resource: Resource.Premise,
              scopes: [Scope.CanView],
            },
            breadcrumbs: false,
          },
          {
            id: "daily-playlists",
            title: i18next.t("customerportal.sidemenu.daily_playlists"),
            type: "item",
            url: `/scheduling/daily-playlists/premise/${premiseId}`,
            icon: PlaylistPlayOutlined,
            restriction: {
              resource: Resource.Premise,
              scopes: [Scope.CanView],
            },
            breadcrumbs: false,
          },
        ],
      },
      {
        id: "channels",
        title: i18next.t("customerportal.sidemenu.channels"),
        type: "item",
        icon: LiveTvOutlined,
        url: `/channels/premise/${premiseId}`,
        products: [NendaProduct.SIGNAGE],
        restriction: {
          resource: Resource.Premise,
          scopes: [Scope.CanView],
        },
        level: [NavigationLevel.PREMISE],
        breadcrumbs: false,
      },
      showDigitalSignage
        ? {
            id: "digital-signage",
            title: i18next.t("customerportal.sidemenu.digital_signage"),
            type: "item",
            url: `/${
              premiseId ? "premise/" + premiseId : "company/" + companyId
            }/digital-signage-playlists`,
            icon: SubscriptionsOutlined,
            restriction: {
              resource: Resource.SignagePlaylist,
              scopes: [Scope.CanView],
            },
            products: [NendaProduct.SIGNAGE],
            level: [NavigationLevel.PREMISE, NavigationLevel.COMPANY],
            breadcrumbs: false,
          }
        : undefined,
      showPromotions
        ? {
            id: "promotions",
            title: i18next.t("customerportal.sidemenu.promotions"),
            type: "item",
            icon: MmsOutlined,
            url: `/promotions/${
              premiseId ? "premise/" + premiseId : "company/" + companyId
            }`,
            restriction: {
              resource: Resource.SignagePromotion,
              scopes: [Scope.CanView],
            },
            breadcrumbs: false,
          }
        : undefined,
      {
        id: "frontlayer",
        title: i18next.t("customerportal.sidemenu.frontlayer_settings"),
        type: "item",
        url: `/frontlayer/premise/${premiseId}`,
        products: [NendaProduct.FRONTLAYER],
        level: [NavigationLevel.PREMISE],
        icon: BrandingWatermarkOutlined,
        breadcrumbs: false,
        restriction: {
          resource: Resource.Premise,
          scopes: [Scope.CanEdit],
        },
      },
      {
        id: "premises",
        title: i18next.t("customerportal.sidemenu.premises"),
        type: "item",
        level: [NavigationLevel.COMPANY],
        url: premiseId
          ? `/premises/premise/${premiseId}`
          : `/premises/company/${companyId}`,
        icon: AddBusinessOutlinedIcon,
        restriction: {
          resource: Resource.Company,
          scopes: [Scope.CanEdit],
        },
        breadcrumbs: false,
      },
      {
        id: "media-library",
        title: i18next.t("customerportal.sidemenu.assets"),
        type: "item",
        icon: PermMediaOutlined,
        url: `/media-library/${
          premiseId ? "premise/" + premiseId : "company/" + companyId
        }`,
        restriction: { resource: Resource.Asset, scopes: [Scope.CanView] },
        products: [NendaProduct.SIGNAGE],
        breadcrumbs: false,
      },
      {
        id: "units",
        title: capitalize(i18next.t("glossary.unit", { count: 2 })),
        type: "item",
        url: `/premise/${premiseId}/units`,
        icon: TvOutlined,
        level: [NavigationLevel.PREMISE],
        restriction: {
          resource: Resource.Premise,
          scopes: [Scope.CanAdministrate],
        },
      },
    ].filter((item) => item !== undefined),
  };

  return isGlobalAdmin && !companyId && !premiseId ? [adminMenu] : [mainMenu];
};
