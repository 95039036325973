import AddIcon from "@mui/icons-material/Add";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import React, { useEffect } from "react";
import {
  AssetSource,
  AssetType,
  IContentChannel,
  ILeanAsset,
} from "../../../../../types/NendaTypes";
import TextInput from "../../ui-components/input/TextInput";
import EditPlaylist from "../DailyPlaylist/EditPlaylist";
import ChannelLogo from "./ChannelLogo";
import ChannelPoster from "./ChannelPoster";
import ToggleList from "./components/ToggleList";
import { t } from "i18next";

const initChannel = {
  name: "",
  category: "",
  description: "",
  isPublic: false,
  isAutoShuffleEnabled: false,
  sortOrder: 0,
  playlist: [],
  hasSound: false,
  posterUrl: undefined,
  adPermissions: {
    external: {
      fullTakeoverAllowed: false,
      bannerAllowed: false,
    },
    signage: {
      fullTakeoverAllowed: false,
      bannerAllowed: false,
    },
  },
  logo: undefined,
};

interface AddEditChannelProps {
  categories: string[];
  onAdd?: (channel: IContentChannel) => void;
  onSave?: (channel: IContentChannel) => void;
  editChannel?: IContentChannel;
  showPlaylist?: boolean;
}

const AddEditChannel: React.FC<AddEditChannelProps> = ({
  categories,
  onAdd,
  onSave,
  editChannel,
  showPlaylist = false,
}) => {
  const [channel, setChannel] = React.useState<IContentChannel>(initChannel);

  useEffect(() => {
    if (!editChannel) return;
    setChannel(editChannel);
  }, [JSON.stringify(editChannel)]);

  const addChannel = () => {
    if (onAdd) onAdd(channel);
  };

  const saveChannel = () => {
    if (onSave) onSave(channel);
  };

  const validateInput = () => {
    if (!channel.name || channel.name == "" || channel.name.length < 3)
      return false;
    if (
      !channel.category ||
      channel.category == "" ||
      channel.category.length < 3
    )
      return false;
    return true;
  };

  const handlePlaylistChange = (playlist: ILeanAsset[]) => {
    setChannel({ ...channel, playlist });
  };
  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        background: theme.palette.transparent.white,
        px: "2rem",
        py: "2rem",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      })}
    >
      <Grid
        container
        columns={{ xs: 1, md: 2 }}
        spacing={2}
        sx={{ borderBottom: "1px solid #ccc", pb: "2rem" }}
      >
        <Grid
          item
          xs={1}
          sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          <TextField
            sx={{ width: "100%" }}
            id="name"
            label={t("customerportal.pages.content_channels.name")}
            variant="standard"
            value={channel.name}
            autoComplete="off"
            onChange={(e) => setChannel({ ...channel, name: e.target.value })}
          />
          <TextInput
            multiline
            variant="standard"
            rows={5}
            placeholder={t("customerportal.pages.content_channels.content")}
            value={channel.description}
            onChange={(e) =>
              setChannel({ ...channel, description: e.target.value })
            }
          />
          <Autocomplete
            freeSolo
            id="category"
            options={categories}
            value={channel.category}
            sx={{ width: "100%" }}
            autoSelect={true}
            onChange={(e, v) => {
              setChannel({ ...channel, category: v || "NONE" });
            }}
            renderInput={(params) => (
              <TextInput
                {...params}
                variant="standard"
                label={t("customerportal.pages.content_channels.category")}
                onChange={(e) => {
                  setChannel({ ...channel, category: e.target.value });
                }}
              />
            )}
          />
          <TextInput
            label={t("customerportal.pages.content_channels.sort")}
            type="number"
            variant="standard"
            value={channel.sortOrder}
            onChange={(e) =>
              setChannel({ ...channel, sortOrder: parseInt(e.target.value) })
            }
          />
        </Grid>
        <Grid item xs={1}>
          <ToggleList channel={channel} setChannel={setChannel} />
        </Grid>
      </Grid>
      <Grid
        container
        columns={{ xs: 1, md: 2 }}
        spacing={2}
        sx={{ borderBottom: "1px solid #ccc", py: "2rem" }}
      >
        <Grid item xs={1}>
          {(channel.playlist.length == 0 ||
            !channel.playlist.some(
              (asset) => asset.type == AssetType.LINEAR_CHANNEL
            )) && (
            <ChannelLogo
              channelId={channel._id}
              logo={channel.logo}
              brandingPositionOverride={
                channel.branding?.brandingPositionOverride
              }
              onLogoChange={(e) => {
                setChannel({ ...channel, logo: e });
              }}
              onBrandingPositionOverrideChange={(overlayPosition) =>
                setChannel({
                  ...channel,
                  branding: {
                    allowed: true,
                    brandingPositionOverride: overlayPosition,
                  },
                })
              }
            />
          )}
        </Grid>
        <Grid item xs={1}>
          <ChannelPoster
            channelId={channel._id}
            posterUrl={channel.posterUrl || ""}
            onPosterChange={(e) => {
              setChannel({ ...channel, posterUrl: e });
            }}
          />
        </Grid>
      </Grid>
      {showPlaylist && (
        <Grid
          container
          columns={{ xs: 1, md: 2 }}
          spacing={2}
          sx={{ borderBottom: "1px solid #ccc", py: "2rem" }}
        >
          <Grid item xs={2}>
            <EditPlaylist
              playlist={channel.playlist}
              onChange={handlePlaylistChange}
              allowedSources={[
                AssetSource.NENDA,
                AssetSource.REDBEE,
                AssetSource.EXTERNAL,
              ]}
            />
          </Grid>
        </Grid>
      )}
      {editChannel && (
        <LoadingButton
          sx={{ mt: 1 }}
          disabled={!validateInput()}
          onClick={saveChannel}
          //loading={isSending}
          variant="contained"
          startIcon={<SaveAltIcon />}
        >
          {t("customerportal.pages.content_channels.save_changes")}
        </LoadingButton>
      )}
      {!editChannel && (
        <LoadingButton
          sx={{ mt: 1 }}
          disabled={!validateInput()}
          onClick={addChannel}
          //loading={isSending}
          variant="contained"
          startIcon={<AddIcon />}
        >
          {t("customerportal.pages.content_channels.add_channel")}
        </LoadingButton>
      )}
    </Box>
  );
};

export default AddEditChannel;
