import { ReactNode } from "react";
import InputSelect from "../../../ui-components/input/InputSelect";
import { t } from "i18next";
import { Box, MenuItem, Typography } from "@mui/material";
import { IRoomType } from "../../../../../../types/NendaTypes";
import RoomTypeCategoryChip from "./RoomTypeCategoryChip";

interface RoomTypeSelectProps {
  roomTypes: IRoomType[];
  selectedRoomTypeId: string;
  onChange: (roomTypeId: string) => void;
}

const RoomTypeSelect: React.FC<RoomTypeSelectProps> = ({
  roomTypes,
  selectedRoomTypeId,
  onChange,
}): ReactNode => {
  const selectedRoomType = roomTypes.find(
    (roomType) => roomType._id === selectedRoomTypeId
  );

  if (!selectedRoomType) return null;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "start",
        my: "1rem",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Typography variant="h6">
          {t("customerportal.pages.unit.status.room_type")}
        </Typography>
        <RoomTypeCategoryChip roomTypeCategory={selectedRoomType?.category} />
      </Box>
      <InputSelect
        id="company-type"
        value={selectedRoomTypeId}
        title={t("customerportal.pages.unit.room_type_select")}
        defaultValue={selectedRoomTypeId}
        onChange={(e) => onChange(e.target.value)}
        variant={"standard"}
        sx={{ fontSize: "1rem", textTransform: "capitalize" }}
      >
        {roomTypes.map((roomType) => (
          <MenuItem value={roomType._id} key={roomType._id}>
            {roomType.name}
          </MenuItem>
        ))}
      </InputSelect>
    </Box>
  );
};

export default RoomTypeSelect;
