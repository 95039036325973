import { Box, MenuItem, Typography } from "@mui/material";
import InputSelect from "../../ui-components/input/InputSelect";
import { RoomStatus } from "../../../../../types/NendaTypes";
import { t } from "i18next";

interface StatusSelectProps {
  status: RoomStatus;
  onChange: (status: RoomStatus) => void;
}

const StatusSelect: React.FC<StatusSelectProps> = ({ status, onChange }) => {
  const UnitStatusLabelMap = {
    [RoomStatus.ACTIVE]: t("customerportal.pages.unit.status.active"),
    [RoomStatus.INACTIVE]: t("customerportal.pages.unit.status.inactive"),
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", my: "1rem" }}>
      <Typography variant="h6">
        {t("customerportal.pages.unit.status.room_status")}
      </Typography>
      <InputSelect
        id="status"
        value={status}
        title={t("customerportal.pages.unit.status.select")}
        defaultValue={status}
        onChange={(e) => onChange(e.target.value)}
        variant={"standard"}
        sx={{ fontSize: "1rem", textTransform: "capitalize" }}
      >
        {Object.entries(UnitStatusLabelMap).map(([key, value]) => (
          <MenuItem value={key} key={key}>
            {value}
          </MenuItem>
        ))}
      </InputSelect>
    </Box>
  );
};

export default StatusSelect;
