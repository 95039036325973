import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import {
  AssetSource,
  AssetType,
  IAsset,
} from "../../../../../types/NendaTypes";
import TextInput from "../../ui-components/input/TextInput";
import { t } from "i18next";

const style = {
  textField: {
    maxWidth: "50ch",
  },
  select: {
    maxWidth: "20ch",
  },
};

interface AddEditAssetFormProps {
  onAdd: (asset: IAsset) => void;
  allowedSources?: AssetSource[];
  allowedAssetTypes?: AssetType[];
  editAsset?: IAsset;
}

const initialAsset = {
  name: "",
  source: AssetSource.NENDA,
  type: AssetType.VIDEO,
  locator: "https://static.nenda.com/assets/",
} as IAsset;

const AddEditAsset: React.FC<AddEditAssetFormProps> = ({
  onAdd,
  allowedSources = [AssetSource.NENDA],
  allowedAssetTypes = [
    AssetType.VIDEO,
    AssetType.LINEAR_CHANNEL,
    AssetType.IMAGE,
  ],
  editAsset,
}) => {
  const [asset, setAsset]: [IAsset, Dispatch<SetStateAction<IAsset>>] =
    React.useState(initialAsset);

  React.useEffect(() => {
    if (editAsset) setAsset(editAsset);
  }, [editAsset]);

  const addAsset = () => {
    onAdd(asset);
    setAsset({ ...asset, name: "", locator: "" });
  };

  const validateInput = () => {
    if (
      !asset.locator ||
      (asset.source == AssetSource.NENDA &&
        !asset.locator.startsWith("https://"))
    )
      return false;
    if (!asset.name || asset.name == "" || asset.name.length < 3) return false;
    return true;
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 500,
        bgcolor: "background.paper",
        padding: "15px",
      }}
    >
      <Typography
        mt={2}
        mb={1}
        variant="h6"
        sx={{ display: "flex", justifyContent: "left" }}
      >
        {t("customerportal.pages.content_channels.add_asset")}
      </Typography>
      <Stack spacing={2}>
        <TextInput
          sx={style.textField}
          id="name"
          variant="standard"
          label={t("customerportal.pages.content_channels.name")}
          value={asset.name}
          autoComplete="off"
          onChange={(e) => setAsset({ ...asset, name: e.target.value })}
        />
        {!allowedSources.every((x) => x === AssetSource.NENDA) && (
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Source
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={asset.source}
              onChange={(e) =>
                setAsset({
                  ...asset,
                  source: e.target.value as AssetSource,
                  locator: "",
                })
              }
            >
              <FormControlLabel
                value={AssetSource.NENDA}
                control={<Radio />}
                label="Nenda"
              />
              <FormControlLabel
                value={AssetSource.REDBEE}
                control={<Radio />}
                label="RedBee"
              />
              <FormControlLabel
                value={AssetSource.EXTERNAL}
                control={<Radio />}
                label="External"
              />
            </RadioGroup>
          </FormControl>
        )}
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">Type</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={asset.type}
            onChange={(e) =>
              setAsset({ ...asset, type: e.target.value as AssetType })
            }
          >
            <FormControlLabel
              value={AssetType.VIDEO}
              control={<Radio />}
              label={t("customerportal.pages.content_channels.video")}
            />
            {asset.source == AssetSource.REDBEE &&
            allowedAssetTypes.includes(AssetType.LINEAR_CHANNEL) ? (
              <FormControlLabel
                value={AssetType.LINEAR_CHANNEL}
                control={<Radio />}
                label={t("customerportal.pages.content_channels.linear")}
              />
            ) : (
              <Tooltip
                title={
                  asset.source != AssetSource.REDBEE
                    ? "Only allowed for Redbee assets"
                    : "A playlist may only contain one linear channel and then nothing else"
                }
              >
                <FormControlLabel
                  value={AssetType.LINEAR_CHANNEL}
                  control={<Radio />}
                  label={t("customerportal.pages.content_channels.linear")}
                  disabled={true}
                />
              </Tooltip>
            )}
            <Tooltip title="Not supported">
              <FormControlLabel
                value={AssetType.IMAGE}
                control={<Radio />}
                label={t("customerportal.pages.content_channels.image")}
                disabled={true}
              />
            </Tooltip>
          </RadioGroup>
        </FormControl>
        <TextInput
          sx={style.textField}
          id="locator"
          variant="standard"
          label={asset.source == AssetSource.REDBEE ? "Asset ID" : "URL"}
          value={asset.locator}
          autoComplete="off"
          onChange={(e) => {
            setAsset({ ...asset, locator: e.target.value });
          }}
        />
        <LoadingButton
          sx={{ mt: 1 }}
          disabled={!validateInput()}
          onClick={addAsset}
          //loading={isSending}
          variant="contained"
          startIcon={<AddIcon />}
        >
          {t("customerportal.pages.content_channels.add_asset")}
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default AddEditAsset;
