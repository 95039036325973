import { useTranslation } from "react-i18next";
import { MenuItem, Select, Box } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { useState } from "react";
import { useSelector } from "react-redux";
import { CustomerPortalState } from "../store";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const [language, setLanguage] = useState(
    localStorage.getItem("selectedLanguage") || i18n.language
  );

  const changeLanguage = (lng: string) => {
    setLanguage(lng);
    localStorage.setItem("selectedLanguage", lng); 
    i18n.changeLanguage(lng).then(() => {
      window.location.reload(); // 
    });
  };

  const opened = useSelector(
    (state: CustomerPortalState) => state.customization.opened
  );

  return (
    <Box
      paddingLeft={2}
      id="language-switcher"
      sx={{
        position: "absolute",
        left: opened ? "0" : "10px",
        bottom: "10px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <LanguageIcon sx={{ marginBottom: "3px", color: "white" }} />
      {opened && (
        <Select
          labelId="language-select-label"
          value={language}
          onChange={(e) => changeLanguage(e.target.value)}
          variant="standard"
          MenuProps={{
            disablePortal: true,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }}
          sx={{
            backgroundColor: "transparent",
            color: "white",
            "& .MuiSelect-select": { color: "white" },
            "& .MuiSvgIcon-root": { color: "white" },
          }}
        >
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="sv">Svenska</MenuItem>
        </Select>
      )}
    </Box>
  );
};

export default LanguageSwitcher;
