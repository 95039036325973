import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { t } from "i18next";
import React from "react";
import { OverlayPosition } from "../../../../../types/NendaTypes";

interface OverlayPositionSelectProps {
  overlayPosition: OverlayPosition;
  onChange: (overlayPosition: OverlayPosition) => void;
}

const OverlayPositionSelect: React.FC<OverlayPositionSelectProps> = ({
  overlayPosition,
  onChange,
}) => {
  const handleChange = (event: SelectChangeEvent<OverlayPosition>) => {
    onChange(event.target.value as OverlayPosition);
  };
  
  const PrintPosition = {
    [OverlayPosition.TOP_LEFT]: t(
      "customerportal.pages.content_channels.top_left"
    ),
    [OverlayPosition.TOP_RIGHT]: t(
      "customerportal.pages.content_channels.top_right"
    ),
    [OverlayPosition.BOTTOM_LEFT]: t(
      "customerportal.pages.content_channels.bottom_left"
    ),
    [OverlayPosition.BOTTOM_RIGHT]: t(
      "customerportal.pages.content_channels.bottom_right"
    ),
  };

  return (
    <FormControl fullWidth>
      <Select
        labelId="overlay-position-select-label"
        id="overlay-position-select"
        value={overlayPosition}
        onChange={handleChange}
      >
        {Object.values(OverlayPosition).map((position) => (
          <MenuItem key={position} value={position}>
            {PrintPosition[position]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default OverlayPositionSelect;
