import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { CustomerPortalState, store } from "../store";
import { SetError } from "../store/reducers/errorReducer";

export const ErrorNotification: React.FC = () => {
  const error = useSelector(
    (state: CustomerPortalState) => state.error.currentError
  );

  function close() {
    store.dispatch(SetError(undefined));
  }

  return error ? (
    <Snackbar
      open
      autoHideDuration={6000}
      onClose={close}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        onClose={close}
        severity="error"
        sx={{ width: "100%", marginBottom: "4rem" }}
      >
        {`An error occured: ${error}`}
      </Alert>
    </Snackbar>
  ) : null;
};
