import { Box, MenuItem, SelectChangeEvent, Typography } from "@mui/material";
import React from "react";
import {
  SignageSlotAmount,
  SignageSlotAmountValues,
} from "../../../../../../types/NendaTypes";
import InputSelect from "../../../ui-components/input/InputSelect";
import { t } from "i18next";

export interface NumberOfSignageSlotsSelectProps {
  selectedValue?: SignageSlotAmount;
  parentValue?: SignageSlotAmount;
  handleSignageSlotChange: (numberOfSignageSlots: SignageSlotAmount) => void;
}

const NumberOfSignageSlotsSelect: React.FunctionComponent<
  NumberOfSignageSlotsSelectProps
> = ({ selectedValue, handleSignageSlotChange, parentValue }) => {
  const handleChange = (event: SelectChangeEvent<SignageSlotAmount>) => {
    handleSignageSlotChange(event.target.value as SignageSlotAmount);
  };

  return (
    <Box sx={{ my: 1 }}>
      <InputSelect
        labelId="signage-slots-select-label"
        id="signage-slots-select"
        variant="standard"
        value={selectedValue}
        title={t("customerportal.pages.premises.signage_slots")}
        onChange={handleChange}
        sx={{ mb: "1rem" }}
      >
        {SignageSlotAmountValues.map((slotAmount) => (
          <MenuItem key={slotAmount} value={slotAmount}>
            {slotAmount}
          </MenuItem>
        ))}
      </InputSelect>
      <Typography variant="caption" sx={{ mb: 1, fontSize: "0.7rem" }}>
        {!selectedValue &&
          parentValue &&
          `${t(
            "customerportal.pages.premises.current_parent_value"
          )} ${parentValue}`}
      </Typography>
    </Box>
  );
};

export default NumberOfSignageSlotsSelect;
