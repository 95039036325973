import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { ensureArray } from "@shared/arrayUtils";
import { t } from "i18next";
import React from "react";
import {
  FrontLayerBackgroundType,
  IFrontLayerInformationPage,
} from "../../../../../../../types/NendaTypes";
import ColorPicker from "../../../ColorPicker";
import BackgroundSelect from "../BackgroundSelect";

interface InformationProps {
  config: IFrontLayerInformationPage;
  onConfigChange: (config: IFrontLayerInformationPage) => void;
  onUpload: (file: File) => void;
}

const Information: React.FC<InformationProps> = ({
  config,
  onConfigChange,
  onUpload,
}) => {
  const handleBackgroundTypeChange = (
    type: FrontLayerBackgroundType,
    isEnabled: boolean
  ) => {
    const backgroundTypes = [...ensureArray(config.backgroundTypes)];
    if (isEnabled) {
      backgroundTypes.push(type);
    } else {
      const index = backgroundTypes.indexOf(type);
      backgroundTypes.splice(index, 1);
    }

    onConfigChange({
      ...config,
      backgroundTypes,
    });
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <Stack gap={3}>
        <Typography variant="body2">
          {t("customerportal.settings.config_accordions.information.title")}
        </Typography>
        <BackgroundSelect
          canReset={true}
          onColorChange={(value) =>
            onConfigChange({
              ...config,
              backgroundColor: value,
            })
          }
          onTypeChange={handleBackgroundTypeChange}
          onUpload={onUpload}
          activeTypes={config.backgroundTypes || []}
          selectedColor={config.backgroundColor}
          selectedImageUrl={config.backgroundImageUrl}
        />
        <Box
          sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "2rem" }}
        >
          <Box>
            <Typography variant="body2">
              {t("customerportal.settings.config_accordions.information.color")}
            </Typography>
            <ColorPicker
              canReset={true}
              color={config.defaultItemColor || ""}
              onChange={(value) =>
                onConfigChange({
                  ...config,
                  defaultItemColor: value,
                })
              }
            />
          </Box>
          <Box>
            <Typography variant="body2">
              {t(
                "customerportal.settings.config_accordions.information.selected_color"
              )}
            </Typography>
            <ColorPicker
              canReset={true}
              color={config.defaultSelectedItemColor || ""}
              onChange={(value) =>
                onConfigChange({
                  ...config,

                  defaultSelectedItemColor: value,
                })
              }
            />
          </Box>
          <Box>
            <Typography variant="body2">
              {t(
                "customerportal.settings.config_accordions.information.border_color"
              )}
            </Typography>
            <ColorPicker
              canReset={true}
              color={config.defaultItemBorderColor || ""}
              onChange={(value) =>
                onConfigChange({
                  ...config,
                  defaultItemBorderColor: value,
                })
              }
            />
          </Box>
          <Box>
            <Typography variant="body2">
              {t(
                "customerportal.settings.config_accordions.information.selected_border_color"
              )}
            </Typography>
            <ColorPicker
              canReset={true}
              color={config.defaultSelectedItemBorderColor || ""}
              onChange={(value) =>
                onConfigChange({
                  ...config,
                  defaultSelectedItemBorderColor: value,
                })
              }
            />
          </Box>
          <Box>
            <Typography variant="body2">
              {t(
                "customerportal.settings.config_accordions.information.font_color"
              )}
            </Typography>
            <ColorPicker
              canReset={true}
              color={config.fontColor}
              onChange={(value) =>
                onConfigChange({
                  ...config,
                  fontColor: value,
                })
              }
            />
          </Box>
          <Box>
            <Typography variant="body2">
              {t(
                "customerportal.settings.config_accordions.information.selected_font_color"
              )}
            </Typography>
            <ColorPicker
              canReset={true}
              color={config.selectedFontColor}
              onChange={(value) =>
                onConfigChange({
                  ...config,
                  selectedFontColor: value,
                })
              }
            />
          </Box>
          <Box>
            <Typography variant="body2">
              {t(
                "customerportal.settings.config_accordions.information.change_color_selection"
              )}
            </Typography>
            <RadioGroup
              row
              name="borderSelectionEnabled"
              value={config.isBorderSelectionEnabled ? "enabled" : "disabled"}
              onChange={(event) => {
                const value = (event.target as HTMLInputElement).value;
                onConfigChange({
                  ...config,
                  isBorderSelectionEnabled: value === "enabled" ? true : false,
                });
              }}
            >
              <FormControlLabel
                value={"enabled"}
                control={<Radio />}
                label={t(
                  "customerportal.settings.config_accordions.information.enabled"
                )}
              />
              <FormControlLabel
                value={"disabled"}
                control={<Radio />}
                label={t(
                  "customerportal.settings.config_accordions.information.disabled"
                )}
              />
            </RadioGroup>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default Information;
