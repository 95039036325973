import { Box, Link, useMediaQuery, useTheme } from "@mui/material";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import { t } from "i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  IRoom,
  IRoomType,
  NendaProduct,
} from "../../../../../types/NendaTypes";
import ConfirmationDialog from "../../ConfirmationDialog";
import { CustomerPortalState, store } from "../../store";
import { selectRoomTypesByPremiseGroupIds } from "../../store/reducers/organizationUnitReducer";
import { deleteScreen } from "../../store/reducers/screenReducer";
import DefaultDataGrid, {
  RowOption,
} from "../../ui-components/dataGrid/DefaultDataGrid";
import { ProductIcon } from "../../ui-components/table/tableProps/premises";

interface UnitListProps {
  units: IRoom[];
}

interface UnitListRow {
  id: string;
  premiseId: string;
  name: string;
  type: string;
  products: string;
  status: string;
}

const UnitList: React.FC<UnitListProps> = ({ units }) => {
  const [screenToDelete, setScreenToDelete] = useState<string>("");
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const distinctHotelChains = [
    ...new Set(units.map((unit) => unit.hotelChain)),
  ];

  const roomTypesByPremiseGroup = useSelector(
    (state: CustomerPortalState): Record<string, IRoomType[]> | undefined =>
      selectRoomTypesByPremiseGroupIds(
        state,
        distinctHotelChains.map((hotelChain) => hotelChain.toString())
      )
  );

  if (!roomTypesByPremiseGroup) {
    return null;
  }

  const rows: UnitListRow[] = units.map(
    (unit): UnitListRow => ({
      id: unit._id,
      premiseId: unit.hotel,
      name: unit.name,
      type:
        (roomTypesByPremiseGroup[unit.hotelChain] || []).find(
          (x) => x._id === unit.roomType
        )?.name || "",
      products: unit.nendaProducts.join(", "),
      status: unit.status,
    })
  );

  const tableHeadersLocalizationPrefix =
    "customerportal.pages.unit_list.unit_table.column_headers.";

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t(tableHeadersLocalizationPrefix + "name") as string,
      flex: 1,
      renderCell: (params) => {
        return (
          <Link
            sx={{ color: theme.palette.primary.dark, cursor: "pointer" }}
            onClick={() =>
              navigate(`/premise/${params.row.premiseId}/unit/${params.row.id}`)
            }
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "id",
      headerName: t(tableHeadersLocalizationPrefix + "id") as string,
      flex: 1,
    },
    {
      field: "type",
      headerName: t(tableHeadersLocalizationPrefix + "type") as string,
      flex: 1,
    },
    {
      field: "products",
      headerName: t(tableHeadersLocalizationPrefix + "products") as string,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            {params.value.split(",").map((product: NendaProduct) => (
              <ProductIcon product={product.trim()} key={product.trim()} />
            ))}
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: t(tableHeadersLocalizationPrefix + "status") as string,
      flex: 1,
    },
  ];

  const handleSetScreenToDelete = (screenId: string) => {
    setScreenToDelete(screenId);
  };

  const rowOptions = (row: GridValidRowModel): RowOption[] => [
    {
      label: t("common.edit"),
      value: "edit",
      onClick: () => navigate(`/premise/${row.premiseId}/unit/${row.id}`),
    },
    {
      label: t("common.delete"),
      value: "delete",
      onClick: () => handleSetScreenToDelete(row.id),
    },
  ];

  const handleDeleteConfirmationClose = (isConfirmed: boolean) => {
    if (isConfirmed) {
      store.dispatch(deleteScreen(screenToDelete));
    }
    setScreenToDelete("");
  };

  return (
    <Box>
      <ConfirmationDialog
        isOpen={!!screenToDelete}
        title={t("customerportal.pages.unit.delete_confirmation.title")}
        content={t("customerportal.pages.unit.delete_confirmation.description")}
        onClose={handleDeleteConfirmationClose}
      />
      <DefaultDataGrid
        columns={columns}
        rows={rows}
        visibleColumns={{
          id: isMobile ? false : true,
          status: isMobile ? false : true,
        }}
        rowOptions={rowOptions}
      />
    </Box>
  );
};

export default UnitList;
