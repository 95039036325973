import ResetTvIcon from "@mui/icons-material/ResetTv";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box } from "@mui/material";
import React, { useMemo } from "react";
import {
  FrontLayerBackgroundType,
  FrontLayerSectionType,
  IApplicationsPage,
  IFrontLayerClockConfiguration,
  IFrontLayerInformationPage,
  IFrontLayerItem,
  IFrontLayerItemDesignConfiguration,
  IFrontLayerOptions,
  IFrontlayerConfigValues,
  IFrontlayerConfiguration,
  IRadioPage,
} from "../../../../../../types/NendaTypes";
import ConfirmationDialog from "../../../ConfirmationDialog";
import { store } from "../../../store";
import {
  DeleteFrontlayerConfig,
  UploadImage,
  UploadImageForItem,
} from "../../../store/reducers/frontlayerConfigReducer";
import { UpdateOrganizationUnit } from "../../../store/reducers/organizationUnitReducer";
import ApplicationPageConfig from "./Applications/ApplicationPageConfig";
import GlobalConfig from "./GlobalConfig";
import InformationPageConfig from "./Information/InformationPageConfig";
import RadioPageConfig from "./Radio/RadioPageConfig";
import { ViewItem } from "./ViewItem/ViewItem";
import BackButtonConfig from "./components/BackButtonConfig";
import ClockConfig from "./components/ClockConfig";
import HeaderAndFooterConfig from "./components/HeaderAndFooterConfig";
import { t } from "i18next";

interface FrontlayerConfigProps {
  premiseId: string;
  frontlayerConfigId: string;
  initialFrontlayerConfig: IFrontlayerConfigValues;
  frontLayerConfigOptions: IFrontLayerOptions;
  config: IFrontlayerConfigValues;
  isDefaultConfig: boolean;
  setFrontlayerState: (value: IFrontlayerConfigValues) => void;
}

export const FrontlayerConfig: React.FC<FrontlayerConfigProps> = ({
  premiseId,
  config,
  initialFrontlayerConfig,
  frontlayerConfigId,
  frontLayerConfigOptions,
  isDefaultConfig,
  setFrontlayerState,
}) => {
  const [isResetDialogOpen, setIsResetDialogOpen] = React.useState(false);
  const enabledHomeScreenItems = useMemo(() => {
    return config.homeScreenItems.filter((x) => x.enabled);
  }, [config.homeScreenItems]);

  const upload = (file: File, imageCategory: string) => {
    if (!premiseId) return;

    store.dispatch(
      UploadImage(
        premiseId,
        frontlayerConfigId,
        file,
        "frontlayer",
        imageCategory
      )
    );
  };

  const handleBorderSelectionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    context: "home" | "subpages" | "backButton"
  ) => {
    const value = (event.target as HTMLInputElement).value;

    switch (context) {
      case "home":
        setFrontlayerState({
          ...config,
          isBorderSelectionEnabled: value === "enabled" ? true : false,
        });
        break;
      case "subpages":
      case "backButton":
        setFrontlayerState({
          ...config,
          [context]: {
            ...config[context],
            isBorderSelectionEnabled: value === "enabled" ? true : false,
          },
        });
        break;
      default:
        break;
    }
  };

  const itemBackgroundUpload = (
    itemId: string,
    file: File,
    imageCategory: string
  ) => {
    store.dispatch(
      UploadImageForItem(
        premiseId,
        frontlayerConfigId,
        file,
        "frontlayer",
        imageCategory,
        itemId
      )
    );
  };

  const handleViewItemChange = (updatedViewItem: IFrontLayerItem) => {
    const newState = { ...config };

    const updatedIndex = newState.homeScreenItems.findIndex(
      (item) => item.id === updatedViewItem.id
    );

    const updatedItems = [...newState.homeScreenItems];
    updatedItems[updatedIndex] = updatedViewItem;

    newState.homeScreenItems = updatedItems;

    setFrontlayerState({ ...newState });
  };

  const handlResetButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsResetDialogOpen(true);
  };

  const handleResetDialogClose = (isConfirmed: boolean) => {
    setIsResetDialogOpen(false);
    if (isConfirmed) {
      resetFrontlayerState();
    }
  };

  const resetFrontlayerState = () => {
    if (isDefaultConfig) {
      return;
    }
    store.dispatch(DeleteFrontlayerConfig(frontlayerConfigId));
    store.dispatch(
      UpdateOrganizationUnit(premiseId, { frontlayerConfig: null })
    );
  };

  const handleBackButtonConfigChange = (
    mutatedState: IFrontLayerItemDesignConfiguration
  ) => {
    setFrontlayerState({
      ...config,
      backButton: {
        ...config.backButton,
        ...mutatedState,
      },
    });
  };

  const handleApplicationConfigChange = (mutatedState: IApplicationsPage) => {
    setFrontlayerState({
      ...config,
      applications: {
        ...config.applications,
        ...mutatedState,
      },
    });
  };
  const handleInformationConfigChange = (
    mutatedState: IFrontLayerInformationPage
  ) => {
    setFrontlayerState({
      ...config,
      information: {
        ...config.information,
        ...mutatedState,
      },
    });
  };

  const handleRadioConfigChange = (mutatedState: IRadioPage) => {
    setFrontlayerState({
      ...config,
      radio: {
        ...config.radio,
        ...mutatedState,
      },
    });
  };

  const handleClockConfigChange = (
    mutatedState: IFrontLayerClockConfiguration
  ) => {
    setFrontlayerState({
      ...config,
      clock: {
        ...config.clock,
        ...mutatedState,
      },
    });
  };

  const handleHeaderAndFooterChange = (
    mutatedState:
      | IFrontlayerConfiguration[FrontLayerSectionType.HEADER]
      | IFrontlayerConfiguration[FrontLayerSectionType.FOOTER]
  ) => {
    setFrontlayerState({
      ...config,
      ...mutatedState,
    });
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          width: "100%",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box>
            <Box sx={{ display: "flex", gap: "2rem" }}>
              <Box
                sx={(theme) => ({
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "0.5rem",
                  flexWrap: "wrap",
                  aspectRatio: "16 / 9",
                  maxWidth: "60%",
                  height: "280px",
                  border: `1px solid ${theme.palette.grey[400]}`,
                  borderRadius: "0.5rem",
                  paddingTop: "6%",
                  px: "1%",
                  pb: "1%",

                  background: config.backgroundColor,
                  backgroundImage: config.backgroundTypes.includes(
                    FrontLayerBackgroundType.IMAGE
                  )
                    ? `url("${config.backgroundImageUrl}")`
                    : undefined,
                  backgroundSize: "contain",
                  position: "relative",
                })}
              >
                <img
                  style={{
                    position: "absolute",
                    top: 10,
                    left: 10,
                    maxWidth: "100px",
                  }}
                  src={config.logoUrl}
                ></img>
                {enabledHomeScreenItems.map((item) => {
                  return (
                    <ViewItem
                      storeItem={initialFrontlayerConfig.homeScreenItems.find(
                        (x) => x.id === item.id
                      )}
                      key={item.id}
                      homeScreenItem={item}
                      defaultBackgroundColor={config.defaultItemColor}
                      defaultFontColor={config.fontColor}
                      defaultSelectedFontColor={config.defaultSelectedFontColor}
                      defaultBorderColor={config.defaultItemBorderColor}
                      defaultSelectedBackgroundColor={
                        config.defaultSelectedItemColor
                      }
                      selectedBorderColor={
                        config.defaultSelectedItemBorderColor
                      }
                      font={config.font}
                      onChange={handleViewItemChange}
                      onUpload={(file) =>
                        itemBackgroundUpload(item.id, file, "itemBackground")
                      }
                    />
                  );
                })}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  width: "100%",
                }}
              >
                <LoadingButton
                  disabled={isDefaultConfig}
                  loading={false}
                  variant="contained"
                  color="error"
                  onClick={handlResetButtonClick}
                  endIcon={<ResetTvIcon />}
                >
                  {t("customerportal.pages.frontlayer.reset.reset_button")}
                </LoadingButton>
                <ConfirmationDialog
                  title={t(
                    "customerportal.pages.frontlayer.reset.dialog_title"
                  )}
                  content={t(
                    "customerportal.pages.frontlayer.reset.dialog_content"
                  )}
                  isOpen={isResetDialogOpen}
                  onClose={handleResetDialogClose}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <GlobalConfig
              state={config}
              initialFrontlayerConfig={initialFrontlayerConfig}
              setState={setFrontlayerState}
              frontLayerConfigOptions={frontLayerConfigOptions}
              upload={upload}
              onBorderSelect={handleBorderSelectionChange}
            />
            <HeaderAndFooterConfig
              state={{
                header: config.header,
                footer: config.footer,
              }}
              handleChange={handleHeaderAndFooterChange}
            />
            <ClockConfig
              state={config}
              handleChange={handleClockConfigChange}
            />
            <BackButtonConfig
              state={config.backButton}
              handleChange={handleBackButtonConfigChange}
              onBorderSelect={handleBorderSelectionChange}
            />
            <ApplicationPageConfig
              state={config.applications}
              handleChange={handleApplicationConfigChange}
              frontLayerConfigOptions={frontLayerConfigOptions}
              upload={upload}
              config={initialFrontlayerConfig}
            />
            <InformationPageConfig
              state={config.information}
              handleChange={handleInformationConfigChange}
              upload={upload}
              config={initialFrontlayerConfig}
            />
            <RadioPageConfig
              state={config.radio}
              handleChange={handleRadioConfigChange}
              upload={upload}
              config={initialFrontlayerConfig}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
