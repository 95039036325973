import { clipString } from "@client/utils/string";
import { BusinessOutlined } from "@mui/icons-material";
import {
  Box,
  Link,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import { PlayArrow } from "@mui/icons-material";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import {
  ISignagePlaylist,
  NavigationLevel,
  NendaProduct,
  ScheduleContentRefType,
} from "@nendaTypes/NendaTypes";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../../ConfirmationDialog";
import { CustomerPortalState, store } from "../../store";
import { SetContentOverrideModal } from "../../store/reducers/customizationReducer";
import {
  getScreens,
  selectScreensByPremiseAndProduct,
} from "../../store/reducers/screenReducer";
import {
  deleteCompanySignagePlaylist,
  deletePremiseSignagePlaylist,
} from "../../store/reducers/signagePlaylistReducer";
import {
  selectCurrentNavigationLevel,
  selectNavigationScope,
} from "../../store/reducers/workspaceReducer";
import DefaultDataGrid, {
  RowOption,
} from "../../ui-components/dataGrid/DefaultDataGrid";
import PlayContentModal from "../Content/components/PlayContentModal";

type DigitalSignageTableProps = {
  playlists: ISignagePlaylist[];
};

const DigitalSignageTable = ({ playlists }: DigitalSignageTableProps) => {
  const navigate = useNavigate();
  const navigationLevel = useSelector(selectCurrentNavigationLevel);

  const scope = useSelector(selectNavigationScope);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const screens = useSelector((state: CustomerPortalState) =>
    scope.premise
      ? selectScreensByPremiseAndProduct(
          state,
          scope.premise,
          NendaProduct.SIGNAGE
        )
      : []
  );
  useEffect(() => {
    if (scope.premise && !screens.length) {
      store.dispatch(getScreens(scope.premise || ""));
    }
  }, [scope.premise, screens]);

  const handleDelete = (
    playlistId: string,
    playlistScope: NavigationLevel,
    playlistScopeId: string
  ) => {
    setDeleteSignagePlaylist({
      isOpen: true,
      playlistScope,
      playlistScopeId,
      playlistId,
    });
  };

  const [deleteSignagePlaylist, setDeleteSignagePlaylist] = useState<{
    isOpen: boolean;
    playlistScope: NavigationLevel | null;
    playlistId: string;
    playlistScopeId: string;
  }>({
    isOpen: false,
    playlistScope: null,
    playlistId: "",
    playlistScopeId: "",
  });
  const onDeleteConfirmed = (isConfirmed: boolean) => {
    if (isConfirmed) {
      if (deleteSignagePlaylist.playlistScope === NavigationLevel.PREMISE) {
        store.dispatch(
          deletePremiseSignagePlaylist({
            premiseId: deleteSignagePlaylist.playlistScopeId,
            id: deleteSignagePlaylist.playlistId,
          })
        );
      } else if (
        deleteSignagePlaylist.playlistScope === NavigationLevel.COMPANY
      ) {
        store.dispatch(
          deleteCompanySignagePlaylist({
            companyId: deleteSignagePlaylist.playlistScopeId,
            id: deleteSignagePlaylist.playlistId,
          })
        );
      }
    }
    setDeleteSignagePlaylist({
      isOpen: false,
      playlistScope: null,
      playlistId: "",
      playlistScopeId: "",
    });
  };

  const handlePlayPlaylist = (playlistId: string) => {
    store.dispatch(
      SetContentOverrideModal(
        playlistId,
        ScheduleContentRefType.SIGNAGE_PLAYLIST
      )
    );
  };

  const rows = playlists.map((playlist) => ({
    id: playlist._id,
    name: playlist.name,
    company: playlist.company,
    premise: playlist.premise,
    items: playlist.items.length,
    defaultItemDuration: playlist.defaultItemDuration,
    isSoundEnabled: playlist.isSoundEnabled,
    updatedAt: new Date(playlist.updatedAt || new Date()).toLocaleString(),
    duration: playlist.items
      .map((item) => item.durationInSeconds)
      .reduce((a, b) => a! + b!, 0),
  }));

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    {
      field: "play",
      headerName: "",
      width: 50,
      renderCell: (params) => (
        <Tooltip
          title={t(
            "customerportal.pages.digital_signage.list.header.play_tooltip"
          )}
        >
          <IconButton
            color="primary"
            onClick={() => handlePlayPlaylist(params.row.id)}
          >
            <PlayArrow />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: "name",
      headerName: t(
        "customerportal.pages.digital_signage.list.header.name"
      ) as string,
      flex: 1,
      renderCell: (params) => {
        const navigationLink = `/${navigationLevel}/${
          navigationLevel === "premise" ? scope.premise : scope.company
        }/digital-signage-playlists/${params.row.id}`;
        return (
          <Link
            onClick={() => navigate(navigationLink)}
            sx={{ cursor: "pointer" }}
          >
            {clipString(params.row.name, 50)}
          </Link>
        );
      },
    },
    { field: "updatedAt", headerName: "Updated At", flex: 1 },
    { field: "company", headerName: "Company", flex: 1 },
    { field: "premise", headerName: "Premise", flex: 1 },
    {
      field: "isCompanyPlaylist",
      headerName: "",
      width: 50,
      renderCell: (params) => {
        return !params.row.premise ? (
          <Tooltip
            title={t("customerportal.pages.digital_signage.company_playlist")}
          >
            <BusinessOutlined />
          </Tooltip>
        ) : null;
      },
    },
    {
      field: "duration",
      headerName: t(
        "customerportal.pages.digital_signage.list.header.total_duration.title"
      ) as string,
      description: t(
        "customerportal.pages.digital_signage.list.header.total_duration.description"
      ) as string,
      flex: 1,
      renderCell: (params) => (
        <Typography variant="body1">{`${params.row.duration}s`}</Typography>
      ),
    },
    {
      field: "items",
      headerName: t(
        "customerportal.pages.digital_signage.list.header.num_of_assets.title"
      ) as string,
      description: t(
        "customerportal.pages.digital_signage.list.header.num_of_assets.description"
      ) as string,
      flex: 1,
      renderCell: (params) => (
        <Typography variant="body1">{`${params.row.items}`}</Typography>
      ),
    },
    {
      field: "isSoundEnabled",
      width: isMobile ? 50 : 100,
      description: t(
        "customerportal.pages.digital_signage.list.header.sound.description"
      ) as string,
      headerName: t(
        "customerportal.pages.digital_signage.list.header.sound.title"
      ) as string,
      renderCell: (params) => {
        return (
          <Typography variant="body1">
            {params.row.isSoundEnabled
              ? (t(
                  "customerportal.pages.digital_signage.list.header.sound.on"
                ) as string)
              : (t(
                  "customerportal.pages.digital_signage.list.header.sound.off"
                ) as string)}
          </Typography>
        );
      },
    },
  ];

  const rowOptions = (row: GridValidRowModel): RowOption[] => [
    {
      label: t("common.delete"),
      value: "delete",
      onClick: () =>
        handleDelete(
          row.id,
          row.premise ? NavigationLevel.PREMISE : NavigationLevel.COMPANY,
          row.premise || row.company
        ),
    },
  ];
  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      <ConfirmationDialog
        isOpen={deleteSignagePlaylist.isOpen}
        onClose={onDeleteConfirmed}
        title={t("customerportal.pages.digital_signage.list.delete_playlist")}
        content={t(
          "customerportal.pages.digital_signage.list.delete_playlist_description",
          {
            playlistName: playlists.find(
              (playlist) => playlist._id === deleteSignagePlaylist.playlistId
            )?.name,
          }
        )}
      />
      <PlayContentModal screens={screens} />
      <DefaultDataGrid
        rows={rows}
        columns={columns}
        sortingConfig={[{ field: "updatedAt", sort: "desc" }]}
        rowOptions={rowOptions}
        visibleColumns={{
          updatedAt: false,
          company: false,
          premise: false,
          id: false,
          duration: isMobile ? false : true,
          isSoundEnabled: isMobile ? false : true,
          items: isMobile ? false : true,
        }}
      />
    </Box>
  );
};

export default DigitalSignageTable;
