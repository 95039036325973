import { Typography } from "@mui/material";
import { GridColDef, GridRowsProp, GridValidRowModel } from "@mui/x-data-grid";
import { t } from "i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ConfirmationDialog from "../../ConfirmationDialog";
import { store } from "../../store";
import { getCompanies } from "../../store/reducers/companyReducer";
import { deleteUser, selectUsers } from "../../store/reducers/userReducer";
import DefaultDataGrid, {
  RowOption,
} from "../../ui-components/dataGrid/DefaultDataGrid";

const UserList = ({ onEditUser }: { onEditUser: (value: string) => void }) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState<string>();
  const companies = useSelector(getCompanies);
  const handleConfirmCloseDialog = (isConfirmed: boolean) => {
    if (isConfirmed && userToDelete) {
      store.dispatch(deleteUser(userToDelete));
    }
    setShowDeleteDialog(false);
  };

  const handleDeleteUser = (userId: string) => {
    setUserToDelete(userId);
    setShowDeleteDialog(true);
  };

  const users = useSelector(selectUsers);
  const columns: GridColDef[] = [
    {
      field: "username",
      disableColumnMenu: true,
      headerName: "Username",
      flex: 1,
      renderCell: (params) => (
        <Typography variant="body1" sx={{ pl: "1rem" }}>
          {params.value}
        </Typography>
      ),
      renderHeader: () => (
        <Typography variant="body2" sx={{ pl: "1rem" }}>
          {t("customerportal.pages.users.email")}
        </Typography>
      ),
    },
    {
      field: "role",
      headerName: t("customerportal.pages.users.role") ?? "Role",
      disableColumnMenu: true,
      width: 150,
      renderCell: (params) => (
        <Typography variant="body1">{params.value}</Typography>
      ),
    },
    {
      field: "company",
      headerName: t("customerportal.pages.users.company") ?? "Company",
      disableColumnMenu: true,
      width: 250,
      renderCell: (params) => {
        return (
          <Link to={`/dashboard/company/${params.value._id}`}>
            {params.value.name}
          </Link>
        );
      },
    },
    {
      field: "created_at",
      headerName: t("customerportal.pages.users.created") ?? "Created",
      width: 120,
      valueFormatter: (params) => {
        return new Date(params as string).toLocaleDateString();
      },
    },
    {
      field: "updated_at",
      headerName: t("customerportal.pages.users.updated") ?? "Updated",
      width: 120,
      valueFormatter: (params) => {
        return new Date(params as string).toLocaleDateString();
      },
    },
  ];
  const rows: GridRowsProp = users.data?.map((user) => ({
    id: user._id,
    username: user.email,
    role: user.type,
    company: companies.find((c) => c._id === user.company) || "",
    updated_at: user.updatedAt,
    created_at: user.createdAt,
  }));

  const rowOptions = (row: GridValidRowModel): RowOption[] => [
    {
      label: t("customerportal.pages.users.edit"),
      value: "edit",
      onClick: () => onEditUser(row.id),
    },
    {
      label: t("customerportal.pages.users.delete"),
      value: "delete",
      onClick: () => handleDeleteUser(row.id),
    },
  ];

  return (
    <>
      <DefaultDataGrid
        columns={columns}
        rows={rows}
        sortingConfig={[{ field: "updated_at", sort: "desc" }]}
        rowOptions={rowOptions}
      />
      <ConfirmationDialog
        title={t("customerportal.pages.users.confirmation.delete_user")}
        content={t(
          "customerportal.pages.users.confirmation.cannot_be_restored"
        )}
        isOpen={showDeleteDialog}
        onClose={handleConfirmCloseDialog}
      />
    </>
  );
};

export default UserList;
