import { Box, Chip, Tooltip, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { limitDisplayItems } from "./AdditionalInformation";
import {
  ContentPackage,
  IRoom,
} from "../../../../../../../../../../types/NendaTypes";

const ModalFlowSummary = ({
  rooms,
  packages,
}: {
  rooms: IRoom[];
  packages: ContentPackage[] | undefined;
}) => {
  const theme = useTheme();
  if (!rooms || !packages) return null;

  const price = (
    packages.reduce((acc, p) => acc + p.price, 0) * rooms.length
  ).toFixed(2);
  const {
    displayableItems: displayableRooms,
    additionalItemsText,
    restOfItems,
  } = limitDisplayItems(rooms, 3);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        justifyContent: "space-between",
        borderRadius: "3px",
        py: "0.5rem",
        px: "1rem",
      }}
    >
      {rooms.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="sectionTitle">
            {t(
              "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.rooms"
            )}
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "0.2rem",
              alignItems: "center",
            }}
          >
            {displayableRooms.map((r) => (
              <Chip
                size="small"
                label={r.name}
                sx={{ fontSize: "0.7rem" }}
                key={r._id}
              />
            ))}
            <Tooltip
              title={
                <>
                  {restOfItems.map((i) => (
                    <Typography variant="body1" key={i.name}>
                      {i.name}
                    </Typography>
                  ))}
                </>
              }
            >
              <Typography
                variant="body1"
                fontSize="0.8rem"
                px="0.5rem"
                sx={{ color: theme.palette.grey[500], cursor: "pointer" }}
              >
                {additionalItemsText}
              </Typography>
            </Tooltip>
          </Box>
        </Box>
      )}
      {packages.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <Typography variant="sectionTitle">
            {t(
              "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.packages"
            )}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              gap: "0.5rem",
            }}
          >
            {packages.map((p) => (
              <Chip
                size="small"
                label={p.name}
                key={`package-${p.id}`}
                sx={{ fontSize: "0.7rem" }}
              />
            ))}
          </Box>
        </Box>
      )}

      {packages.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-end",
            flex: 1,
          }}
        >
          <Typography variant="sectionTitle">Total Price</Typography>
          <Typography variant="h6" sx={{ fontSize: "1rem" }}>
            {price}
            {t(
              "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.sek_short_per_month"
            )}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ModalFlowSummary;
