import { BusinessOutlined } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { NavSelectItem, ScopeSelect } from "../TopNavSelect";
import { t } from "i18next";

type NavCompanySelectProps = {
  disabled: boolean;
  companies: NavSelectItem[];
  selected: string;
};
const NavCompanySelect = ({
  disabled,
  companies,
  selected,
}: NavCompanySelectProps) => {
  const navigate = useNavigate();

  const handleSelectCompany = (value: string) => {
    if (value) {
      navigate("/dashboard/company/" + value);
    } else {
      navigate("/" + value);
    }
  };

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Box
        sx={(theme) => ({
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          px: "1rem",
        })}
      >
        <ScopeSelect
          disabled={disabled}
          icon={
            <Tooltip title={t("customerportal.header.company")}>
              <BusinessOutlined />
            </Tooltip>
          }
          options={companies}
          onSelect={handleSelectCompany}
          selectedId={selected}
        />
      </Box>

      <Box
        component="span"
        sx={(theme) => ({
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        })}
      >
        {/* <InvertedArrow /> */}
      </Box>
    </Box>
  );
};

const InvertedArrow = () => {
  return (
    <Box>
      <svg
        width="10px"
        height="100%"
        viewBox="0 0 14 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M14 25L0 0V50L14 25Z" fill="url(#paint0_linear_1746_8137)" />
        <defs>
          <linearGradient
            id="paint0_linear_1746_8137"
            x1="0"
            y1="50"
            x2="22.4394"
            y2="45.8716"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1A00EA" stopOpacity="0.06" />
            <stop offset="1" stopColor="#1A00EB" stopOpacity="0.07" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
};

export default NavCompanySelect;
