import {
  RadioButtonCheckedOutlined,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import { cloneElement, ReactElement } from "react";

type RadioButtonCardProps = {
  selected: boolean;
  icon: ReactElement;
  title: string;
  description: string;
  onClick: () => void;
  maxWidth?: string;
};
const RadioButtonCard = ({
  selected,
  icon,
  title,
  description,
  onClick,
  maxWidth,
}: RadioButtonCardProps) => {
  const theme = useTheme();
  return (
    <Box
      onClick={onClick}
      sx={{
        p: "1rem",
        border: "0.5px solid #d3d3d3",
        borderRadius: "0.2rem",
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        maxWidth,
        outline: selected
          ? `2px solid ${theme.palette.primary.light}`
          : "2px solid transparent",
        transition: "all 0.2s",
        background: selected ? "#f5f5f5" : "transparent",
        "&:hover": {
          cursor: "pointer",
          outline: selected
            ? `2px solid ${theme.palette.primary.light}`
            : `2px solid #f5f5f5`,
          backgroundColor: "#f5f5f5",
        },
      }}
    >
      <Box
        sx={{
          width: "20%",
          display: "flex",
        }}
      >
        <Box sx={{ width: "50px", height: "50px" }}>
          {cloneElement(icon, {
            style: {
              width: "100%",
              height: "100%",
              color: theme.palette.primary.light,
            },
          })}
        </Box>
      </Box>
      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <Box sx={{ alignSelf: "left" }}>
          <Typography variant="h6">{title}</Typography>
        </Box>
        <Box sx={{ alignSelf: "left" }}>
          <Typography>{description}</Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignSelf: "start" }}>
        {selected ? (
          <RadioButtonCheckedOutlined />
        ) : (
          <RadioButtonUncheckedOutlined />
        )}
      </Box>
    </Box>
  );
};

export default RadioButtonCard;
