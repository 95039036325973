import {
  Box,
  Button,
  Step,
  StepButton,
  Stepper,
  Typography,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import { Dispatch, SetStateAction } from "react";
import { IAsset } from "../../../../../../../types/NendaTypes";
import EpicTextInput from "../../../../ui-components/input/EpicTextInput";
import {
  CreatePromotionFormState,
  CreateUpdateObject,
} from "../CreateEditPromotionModal";
import AssignToScreensForm from "./AssignToScreensForm";
import ConfirmPromotion from "./ConfirmPromotion";
import SelectAssets from "./SelectAssets";
import SelectRulesForm from "./SelectRulesForm";
import { store } from "../../../../store";
import { ToggleCreateAssetDialog } from "../../../../store/reducers/assetReducer";

export type ScheduleRule = "date" | "time" | "days";

const steps = [
  "customerportal.pages.promotions.promotion_name",
  "customerportal.pages.promotions.select_assets",
  "customerportal.pages.promotions.promotion_details",
  "customerportal.pages.promotions.assign_to_screens",
  "common.confirm",
];

const StepperForm = ({
  state,
  setState,
  assets,
  activeStep,
  setActiveStep,
  isValidStepClick,
  validationRules,
  appliedScheduleRules,
  onToggleScheduleRuleApplied,
  formattedState,
}: {
  state: CreatePromotionFormState;
  assets: IAsset[];
  setState: Dispatch<SetStateAction<CreatePromotionFormState>>;
  companyId: string;
  premiseId?: string;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  progressConditions: Record<number, boolean>;
  isValidStepClick: (step: number) => boolean;
  validationRules: {
    maxDuration: number;
    minDuration: number;
  };
  appliedScheduleRules: ScheduleRule[];
  onToggleScheduleRuleApplied: (rule: ScheduleRule, isApplied: boolean) => void;
  formattedState: CreateUpdateObject;
}) => {
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, name: e.target.value }));
  };
  const theme = useTheme();

  const handleStep = (step: number) => () => {
    if (isValidStepClick(step)) {
      setActiveStep(step);
    }
  };

  const handleOpenUploadAssetModal = () => {
    store.dispatch(ToggleCreateAssetDialog());
  };

  return (
    <Box sx={{ width: "100%", py: "0.5rem" }}>
      <Stepper
        nonLinear
        activeStep={activeStep}
        sx={{
          "&.MuiStepper-root": {
            [theme.breakpoints.down("md")]: {
              display: "flex",
              justifyContent: "center !important",
              alignItems: "center",
              gap: "0.5rem",
            },
          },
          "& .MuiStepConnector-root": {
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          },
        }}
      >
        {steps.map((label, index) => {
          const stepIsActive = activeStep === index;
          return (
            <Step key={label} completed={index < activeStep}>
              <StepButton
                sx={{
                  "& .MuiStepLabel-label": {
                    color: stepIsActive ? "inherit" : theme.palette.grey[700],
                    [theme.breakpoints.down("md")]: {
                      display: "none",
                    },
                  },
                }}
                onClick={handleStep(index)}
              >
                {t(label)}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <Box
        sx={{
          pt: "2rem",
          pb: "1rem",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: activeStep === 1 ? "space-between" : "center",
          gap: "2rem",
        }}
      >
        <Typography variant="h6">{t(steps[activeStep])}</Typography>
        {activeStep === 1 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "0.3rem",
              my: "1rem",
              gap: "1rem",
            }}
          >
            <Button onClick={handleOpenUploadAssetModal} variant="outlined">
              {t("common.upload_new_asset")}
            </Button>
          </Box>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
        {activeStep === 0 && (
          // Promotion Name
          <Box pt="4rem" pb="8rem">
            <EpicTextInput
              onChange={handleNameChange}
              value={state.name}
              placeholder={
                t(
                  "customerportal.pages.promotions.promotion_name_placeholder"
                ) as string
              }
            />
            <Box>
              <Typography variant="caption" sx={{ fontSize: "0.6rem" }}>
                {t("customerportal.pages.promotions.name_helper_text")}
              </Typography>
            </Box>
          </Box>
        )}
        {activeStep === 1 && (
          // Select Assets
          <SelectAssets
            state={state}
            setState={setState}
            assets={assets}
            setActiveStep={setActiveStep}
            isValidStepClick={isValidStepClick}
          />
        )}
        {activeStep === 2 && (
          // Select Date Rules and asset duration
          <SelectRulesForm
            state={state}
            assets={assets}
            setState={setState}
            appliedRules={appliedScheduleRules}
            onToggleRuleApplied={onToggleScheduleRuleApplied}
            validationRules={validationRules}
          />
        )}
        {
          // Apply to Screens
          activeStep === 3 && (
            <AssignToScreensForm state={state} setState={setState} />
          )
        }
        {activeStep === 4 && (
          <ConfirmPromotion
            promotionState={{ ...formattedState, screens: state.screens }}
          />
        )}
      </Box>
    </Box>
  );
};

export default StepperForm;
