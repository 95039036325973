import {
  findClosestColor,
  hslToRgb,
  preDefinedColors,
  rgbToHex,
} from "./color";

export const capitalizeString = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const isEmail = (mailString: string) => {
  const mailFormat =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  //const mailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //const mailFormat = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (!mailString) {
    return false;
  }
  return mailString.match(mailFormat) != null;
};

export function clipString(text: string, maxLength: number) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength) + "...";
  }
}

export const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Calculate the hue based on the hash value
  const hue = hash % 360;

  // Set lightness to a value between 40 and 70 (adjust as needed)
  const lightness = 40 + (hash % 30);

  // Convert hue and lightness to RGB format
  const rgbColor = hslToRgb(hue / 360, 1, lightness / 100);

  // Convert RGB to HEX
  const generatedHexColor = rgbToHex(rgbColor[0], rgbColor[1], rgbColor[2]);

  const closestColor = findClosestColor(generatedHexColor, preDefinedColors);

  return closestColor;
};

export const containsNumber = (str: string) => {
  return /\d/.test(str);
};

export const errorMessageTemplate = (message, errorCode) => {
  return `${message} ${errorCode ? `(code: ${errorCode})` : ""}`;
};
