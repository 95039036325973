import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { t } from "i18next";
import React from "react";
import { useNavigate } from "react-router-dom";
import Profile from "../Pages/Profile";
import ProfileSettingsButton from "./ProfileSettingsButton";
import SettingsDropDown from "./SettingsDropdown";
import TopNavSelect from "./TopNavigation/TopNavSelect";
import { getValueFromPathname } from "./TopNavigation/helpers/urlParams";
import { Resource } from "../../../../types/NendaTypes";
import TopNavSelectDropdown from "./TopNavSelectDropdown";
import TopNavSettingsButton from "./TopNavSettingsButton";
import { CustomerPortalState } from "../store";
import { ToggleMenu } from "../store/reducers/customizationReducer";
import { useDispatch, useSelector } from "react-redux";

const Header = () => {
  const theme = useTheme();
  const company = getValueFromPathname(window.location.pathname, "company");
  const premise = getValueFromPathname(window.location.pathname, "premise");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const contactLink = () => {
    if (company) {
      return `/contact/${Resource.Company}/${company}`;
    } else if (premise) {
      return `/contact/${Resource.Premise}/${premise}`;
    } else {
      return "/contact";
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElTopNavDropdown, setAnchorElTopNavDropdown] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const topNavDropdownOpen = Boolean(anchorElTopNavDropdown);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event?.currentTarget);
  };
  const handleClickTopNav = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElTopNavDropdown(event?.currentTarget);
  };

  const opened = useSelector(
    (state: CustomerPortalState) => state.customization.opened
  );

  const handleToggleSidebar = () => {
    dispatch(ToggleMenu());
  };

  return (
    <Box
      sx={{
        width: "100%",
        position: "sticky",
        top: 0,
        padding: "0.5rem 0",
        zIndex: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.common.white,
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mx: "1 auto",
          [theme.breakpoints.down("md")]: {
            flexDirection: "row-reverse",
          },
        }}
      >
        {isMobile && (
          <HamburgerMenuButton onClick={handleToggleSidebar} opened={opened} />
        )}
        <Box
          sx={{
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          }}
        >
          <TopNavSelect />
        </Box>
        <Box
          sx={{
            height: "100%",
            padding: "1rem 0rem",
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            borderRadius: "0 0 0 1rem",
            [theme.breakpoints.down("md")]: {
              padding: "0.5rem 0rem",
            },
          }}
        >
          <Box sx={{ [theme.breakpoints.down("md")]: { display: "none" } }}>
            {(!!company || !!premise) && (
              <Button
                variant="outlined"
                size="small"
                onClick={() => navigate(contactLink())}
              >
                <Typography variant="button">
                  {t("customerportal.header.contact_support")}
                </Typography>
              </Button>
            )}
          </Box>
          <Box
            sx={{
              display: "none",
              [theme.breakpoints.down("md")]: { display: "block" },
            }}
          >
            <TopNavSettingsButton onClick={handleClickTopNav} />
          </Box>
          <TopNavSelectDropdown
            open={topNavDropdownOpen}
            handleClick={handleClickTopNav}
            anchorEl={anchorElTopNavDropdown}
          />
          <ProfileSettingsButton onClick={handleClick} />
          <SettingsDropDown
            open={open}
            handleClick={handleClick}
            anchorEl={anchorEl}
          />
        </Box>
        <Profile />
      </Container>
    </Box>
  );
};

export default Header;

type HamburgerMenuButtonProps = {
  onClick: () => void;
  opened: boolean;
};

const HamburgerMenuButton = ({ onClick, opened }: HamburgerMenuButtonProps) => {
  return (
    <Box
      id="toggle-sidebar"
      onClick={onClick}
      sx={{
        position: "absolute",
        left: 0,
        top: "50%",
        transform: "translateY(-50%)",
        width: "4rem",
        height: "4rem",
        padding: "0.5rem",
      }}
    >
      <MenuIcon
        style={{
          height: "100%",
          width: "100%",
          transform: opened ? "scaleX(1)" : "scaleX(-1)",
          transition: "transform 0.3s ease-in-out",
        }}
      />
    </Box>
  );
};
