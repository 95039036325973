import { LocationOnOutlined } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectNavigationScope } from "../../../store/reducers/workspaceReducer";
import { NavSelectItem, ScopeSelect } from "../TopNavSelect";
import {
  getValueFromPathname,
  updateValueInPathname,
} from "../helpers/urlParams";
import { t } from "i18next";

type NavPremiseSelectProps = {
  selectDisabled: boolean;
  premises: NavSelectItem[];
  selected: string;
};
const NavPremiseSelect = ({
  selectDisabled,
  premises,
  selected,
}: NavPremiseSelectProps) => {
  const navigate = useNavigate();
  const scope = useSelector(selectNavigationScope);
  const premiseId = getValueFromPathname(window.location.pathname, "premise");

  const clearPremise = () => {
    if (!scope.company) {
      navigate("/");
    } else {
      navigate("/dashboard/company/" + scope.company);
    }
  };

  const setSelectedPremise = (value: string) => {
    if (!value) {
      clearPremise();
      return;
    }
    if (value === premiseId) return;

    if (!premiseId) {
      navigate("/dashboard/premise/" + value);
    } else {
      const newPath = updateValueInPathname(
        window.location.pathname,
        "premise",
        value
      );

      navigate(newPath);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          px: "1rem",
        }}
      >
        <ScopeSelect
          disabled={selectDisabled}
          icon={
            <Tooltip title={t("customerportal.header.premises")}>
              <LocationOnOutlined />
            </Tooltip>
          }
          options={premises}
          onSelect={setSelectedPremise}
          selectedId={selected}
        />
      </Box>
    </Box>
  );
};

export default NavPremiseSelect;
