import { Box, Chip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { RoomTypeCategory } from "../../../../../../../../../types/NendaTypes";
import { t } from "i18next";
import { useMemo } from "react";
import { RoomWithContentAndType } from "../../../PremiseDashboard";

type RoomSelectProps = {
  rooms: RoomWithContentAndType[];
  selectedRooms: string[];
  setSelectedRooms: (value: string[]) => void;
  selectedRoomTypeCategory?: RoomTypeCategory;
};

export default function RoomSelect({
  rooms,
  selectedRooms,
  setSelectedRooms,
  selectedRoomTypeCategory,
}: RoomSelectProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const roomsByType = rooms.filter(
    (room) => room.type === selectedRoomTypeCategory
  );

  const columns: GridColDef<(typeof rows)[number]>[] = useMemo(() => {
    return [
      { field: "id", headerName: "ID", flex: 1 },
      {
        field: "number",
        headerName: t(
          "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.table_header_room_number"
        ) as string,
        width: 100,
      },
      {
        field: "name",
        headerName: t(
          "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.table_header_room_name"
        ) as string,
        flex: 1,
      },
      {
        field: "packages",
        headerName: t(
          "customerportal.pages.dashboard.cta.quick_nav.content_manager.modal.table_header_current_packages"
        ) as string,
        sortable: false,
        align: "right",
        headerAlign: "right",
        flex: 1,
        renderHeader: (params) => {
          return (
            <Typography variant="body2" align="right" mr="0.5rem">
              {params.colDef.headerName}
            </Typography>
          );
        },
        renderCell: (params) =>
          params.value.map((val) =>
            isMobile ? (
              <Typography key={val} sx={{ mr: "0.5rem" }}>
                {val}
              </Typography>
            ) : (
              <Chip
                key={params.row.id + val}
                sx={{ mr: "0.5rem" }}
                size="small"
                label={val}
              />
            )
          ),
      },
    ];
  }, [isMobile]);

  const rows = useMemo(() => {
    return roomsByType.map((room) => {
      return {
        id: room._id,
        name: room.name,
        number: room.roomIdentifier,
        packages: room.packages.map((p) => p.name),
      };
    });
  }, [roomsByType]);

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        disableColumnMenu
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        columnVisibilityModel={{
          id: false,
          number: isMobile ? false : true,
        }}
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={(params) =>
          setSelectedRooms(params as string[])
        }
        rowSelectionModel={selectedRooms}
      />
    </Box>
  );
}
